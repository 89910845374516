<template>
  <div>
    <div class="size-browser-class">{{ sizeBrowser }}</div>
    <div class="lineup-players is-centered ">
      <div class="lineup-players__container" ref="gamesContainer" :class="{ fallback: gamesContainerWidth === 0 }">
        <div class="lineup-players__field-container" :class="[sizeBrowser < 581 ? 'movil' : '']">
          <div class="modal-substitutions" :class="subsActive ? 'activeSub' : 'deactiveSub'">
            <div class="modal-substitutions__substitution-container">
              <div class="modal-substitutions__substitution-container__header">
                <span
                  >Cambio minuto {{ substitutionModal.min }}'
                  <span class="close-susbtitutions" @click="closeChange()"
                    ><img src="/assets/icons/icn-close.png"/></span
                ></span>
              </div>
              <div class="modal-substitutions__substitution-container__players-container">
                <div class="substitution-player-data-container border">
                  <div class="substitution-player-data-container__img-player">
                    <img
                      v-if="substitutionModal.in.playerImg != ''"
                      :src="substitutionModal.in.playerImg"
                      width="72"
                      alt="Jugador"
                    />
                    <div class="substitution-player-data-container__img-player__team">
                      <img v-if="substitutionModal.in.teamImg != ''" :src="substitutionModal.in.teamImg" alt="team" />
                    </div>
                    <div
                      class="substitution-player-data-container__img-player__img-cont"
                      :style="{ backgroundImage: 'url(' + substitutionModal.in.nationalityimg + ')' }"
                    ></div>
                  </div>
                  <div class="substitution-player-data-container__name-player">
                    {{ substitutionModal.in.name }}
                  </div>
                  <div class="substitution-player-data-container__position-player">
                    {{ substitutionModal.in.position }}
                  </div>
                  <div class="substitution-player-data-container__shirt-player">
                    <span v-if="substitutionModal.in.shirt_number != ''">#</span>{{ substitutionModal.in.shirt_number }}
                  </div>
                  <div class="substitution-player-data-container__substitution-player">
                    <template>
                      <div class="substitution-indicator nomargin">
                        <div>{{ substitutionModal.min }}'</div>
                        <div><img src="/assets/icons/icn_salio.png" /></div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="substitution-player-data-container">
                  <div class="substitution-player-data-container__img-player">
                    <img
                      v-if="substitutionModal.out.playerImg != ''"
                      :src="substitutionModal.out.playerImg"
                      width="72"
                      alt="Jugador"
                    />
                    <div class="substitution-player-data-container__img-player__team">
                      <img v-if="substitutionModal.out.teamImg != ''" :src="substitutionModal.out.teamImg" alt="team" />
                    </div>
                    <div
                      class="substitution-player-data-container__img-player__img-cont"
                      :style="{ backgroundImage: 'url(' + substitutionModal.out.nationalityimg + ')' }"
                    ></div>
                  </div>
                  <div class="substitution-player-data-container__name-player">
                    {{ substitutionModal.out.name }}
                  </div>
                  <div class="substitution-player-data-container__position-player">
                    {{ substitutionModal.out.position }}
                  </div>
                  <div class="substitution-player-data-container__shirt-player">
                    <span v-if="substitutionModal.out.shirt_number != ''">#</span
                    >{{ substitutionModal.out.shirt_number }}
                  </div>
                  <div class="substitution-player-data-container__substitution-player">
                    <template>
                      <div class="substitution-indicator nomargin">
                        <div>{{ substitutionModal.min }}'</div>
                        <div><img src="/assets/icons/icn_ingreso.png" /></div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="lineup-players__field-container__indicador-team" :class="[sizeBrowser < 581 ? 'movil' : '']">
            <div class="lineup-players__field-container__indicador-team__backspace"></div>
            <div
              class="lineup-players__field-container__indicador-team__home-arrow"
              :class="[
                sizeBrowser < 581 ? (teamActive == 'home' ? 'active' : 'disabled') : '',
                sizeBrowser < 581 ? 'movil' : '',
              ]"
            >
              <img src="/assets/icons/arrow_local.png" />
              <div class="logo-Field" :class="[sizeBrowser < 581 ? 'movil' : '']">
                <img :src="homeTeamLogo" width="73" />
              </div>
            </div>
            <div
              class="lineup-players__field-container__indicador-team__away-arrow"
              :class="[
                sizeBrowser < 581 ? (teamActive == 'away' ? 'active' : 'disabled') : '',
                sizeBrowser < 581 ? 'movil' : '',
              ]"
            >
              <img src="/assets/icons/arrow_visita.png" />
              <div class="logo-Field" :class="[sizeBrowser < 581 ? 'movil' : '']">
                <img :src="awayTeamLogo" width="73" />
              </div>
            </div>
            <div class="lineup-players__field-container__indicador-team__backspace"></div>
          </div>
          <div class="lineup-players__field-container__container-cancha" :class="[sizeBrowser < 581 ? 'movil' : '']">
            <div
              class="notification-no-lineups"
              v-if="gameForAnalysis.game_status == 4 && gameForAnalysis.is_live == 0 && numPlayer == 0"
              :class="[sizeBrowser < 581 ? 'movil' : '']"
            >
              Las alineaciones estarán disponibles unos minutos antes de que inicie el partido.
            </div>
            <template v-for="(data, key) in playersHome">
              <template v-if="data.played">
                <div
                  :key="key + 'jersey_home'"
                  class="item-player-field"
                  :style="
                    movil
                      ? { top: data.positionXOrigin + '%', left: data.positionYOrigin + '%' }
                      : { top: data.positionX + '%', left: data.positionY + '%' }
                  "
                  :class="[
                    sizeBrowser < 581 ? (teamActive == 'home' ? 'active' : 'disabled') : '',
                    sizeBrowser < 581 ? 'movil' : '',
                  ]"
                >
                  <div
                    class="item-player-field__jersey"
                    :class="[sizeBrowser < 581 ? 'movil' : '']"
                    :style="
                      data.position != 'Portero'
                        ? {
                            backgroundImage: uniformHome,
                          }
                        : {
                            backgroundImage: uniformHomeG,
                          }
                    "
                  >
                    <div
                      class="yellow_card_div"
                      :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                    ></div>
                    <div
                      class="red_card_div"
                      :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                    ></div>
                    <div
                      class="yellowred_card_div"
                      :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                    ></div>
                    <div class="goal_card_div" :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"></div>
                    <div class="item-player-field__jersey__num" :class="[sizeBrowser < 581 ? 'movil' : '']">
                      {{ data.shirt_number }}
                    </div>
                  </div>
                  <div class="item-player-field__info" :class="[sizeBrowser < 581 ? 'movil' : '']">
                    <div class="item-player-field__info__name" :class="[sizeBrowser < 581 ? 'movil' : '']">
                      {{ data.abrevNamePlayer }}
                    </div>
                    <div
                      class="item-player-field__info__change"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                      v-if="data.substitutionIn || data.substitutionOut"
                      @click="viewChange(data.substitutionDataID)"
                    >
                      <img src="/assets/icons/icn_change.png" />
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-for="(data, key) in playersAway">
              <template v-if="data.played">
                <div
                  :key="key + 'jersey_away'"
                  class="item-player-field"
                  :style="
                    movil
                      ? { top: data.positionXOrigin + '%', left: data.positionYOrigin + '%' }
                      : { top: data.positionX + '%', left: data.positionY + '%' }
                  "
                  :class="[
                    sizeBrowser < 581 ? (teamActive == 'away' ? 'active' : 'disabled') : '',
                    sizeBrowser < 581 ? 'movil' : '',
                  ]"
                >
                  <div
                    class="item-player-field__jersey"
                    :class="[sizeBrowser < 581 ? 'movil' : '']"
                    :style="
                      data.position != 'Portero'
                        ? {
                            backgroundImage: uniformAway,
                          }
                        : {
                            backgroundImage: uniformAwayG,
                          }
                    "
                  >
                    <div
                      class="yellow_card_div"
                      :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                    ></div>
                    <div
                      class="red_card_div"
                      :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                    ></div>
                    <div
                      class="yellowred_card_div"
                      :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                    ></div>
                    <div class="goal_card_div" :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"></div>
                    <div class="item-player-field__jersey__num" :class="[sizeBrowser < 581 ? 'movil' : '']">
                      {{ data.shirt_number }}
                    </div>
                  </div>
                  <div class="item-player-field__info" :class="[sizeBrowser < 581 ? 'movil' : '']">
                    <div class="item-player-field__info__name" :class="[sizeBrowser < 581 ? 'movil' : '']">
                      {{ data.abrevNamePlayer }}
                    </div>
                    <div
                      class="item-player-field__info__change"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                      v-if="data.substitutionIn || data.substitutionOut"
                      @click="viewChange(data.substitutionDataID)"
                    >
                      <img src="/assets/icons/icn_change.png" />
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
        <div class="lineup-players__header-container">
          <span>ALINEACIONES</span>
        </div>
        <div class="lineup-players__teams-menu-container" :class="[sizeBrowser < 581 ? 'movil' : '']">
          <div
            class="button-team-view-lineup"
            :class="[
              sizeBrowser < 581 ? (teamActive == 'home' ? 'active' : 'disabled') : '',
              sizeBrowser < 581 ? 'movil' : '',
            ]"
            @click="viewLineupTeam('home')"
          >
            <div class="lineup-players__teams-menu-container__name_team" :class="[sizeBrowser < 581 ? 'movil' : '']">
              <span class="name_team_full" :class="[sizeBrowser < 581 ? 'movil' : '']">{{ homeTeam.team_name }}</span>
              <span class="name_team_short" :class="[sizeBrowser < 581 ? 'movil' : '']">{{ homeTeam.acronym }}</span>
            </div>
            <div>
              <img
                v-if="homeTeamLogo != ''"
                :src="homeTeamLogo"
                class="logo-home-team"
                :class="[sizeBrowser < 581 ? 'movil' : '']"
                width="64"
              />
            </div>
          </div>
          <div>
            <div class="sep-title-teams-menu" :class="[sizeBrowser < 581 ? 'movil' : '']"></div>
          </div>
          <div
            class="button-team-view-lineup"
            :class="[
              sizeBrowser < 581 ? (teamActive == 'away' ? 'active' : 'disabled') : '',
              sizeBrowser < 581 ? 'movil' : '',
            ]"
            @click="viewLineupTeam('away')"
          >
            <div>
              <img
                v-if="awayTeamLogo != ''"
                :src="awayTeamLogo"
                class="logo-home-away"
                :class="[sizeBrowser < 581 ? 'movil' : '']"
                width="64"
              />
            </div>
            <div class="lineup-players__teams-menu-container__name_team" :class="[sizeBrowser < 581 ? 'movil' : '']">
              <span class="name_team_full" :class="[sizeBrowser < 581 ? 'movil' : '']">{{
                visitingTeam.team_name
              }}</span>
              <span class="name_team_short" :class="[sizeBrowser < 581 ? 'movil' : '']">{{
                visitingTeam.acronym
              }}</span>
            </div>
          </div>
        </div>
        <template v-if="gameForAnalysis.game_status == 4 && gameForAnalysis.is_live == 0 && numPlayer == 0">
          <div class="container-no-lineups">
            <!-- validacion -->
            <div class="container-no-lineups__img-search" :class="[sizeBrowser < 581 ? 'movil' : '']">
              <img src="/assets/icons/graf-busqueda@3x.png" width="74" />
            </div>
            <div class="container-no-lineups__title-info" :class="[sizeBrowser < 581 ? 'movil' : '']">
              Alineaciones sin definir
            </div>
            <div class="container-no-lineups__subtitle-info" :class="[sizeBrowser < 581 ? 'movil' : '']">
              Los equipos no han presentado sus alineaciones. Estas <br />serán dadas minutos antes del inicio del
              partido.
            </div>
          </div>
        </template>
        <template>
          <div class="lineup-players__lineup-container" :class="[sizeBrowser < 581 ? 'movil' : '']">
            <div
              class="lineup-container-home"
              :class="[
                sizeBrowser < 581 ? (teamActive == 'home' ? 'visibleTeam' : 'disabledTeam') : '',
                sizeBrowser < 581 ? 'movil' : '',
              ]"
            >
              <template v-for="(data, key) in playersHome">
                <template v-if="data.played">
                  <div
                    :key="key + 'line_home'"
                    class="lineup-container-home__item-lineup"
                    :class="[sizeBrowser < 581 ? 'movil' : '']"
                  >
                    <div
                      class="lineup-container-home__item-lineup__image-cont"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    >
                      <div
                        class="lineup-container-home__item-lineup__image-cont__item"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <img :src="data.imgPlayer" width="53" alt="Jugador" />
                        <div
                          class="lineup-container-home__item-lineup__image-cont__item__team"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <img :src="data.imgTeam" alt="Jugador" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="lineup-container-home__item-lineup__data-cont"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    >
                      <div
                        class="lineup-container-home__item-lineup__data-cont__name"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <div class="name-list">
                          {{ data.abrevNamePlayer }}
                        </div>
                        <div>
                          <template v-if="data.substitutionOut">
                            <div
                              class="substitution-indicator margin-flag-top-subs"
                              :class="[sizeBrowser < 581 ? 'movil' : '']"
                            >
                              <div>{{ data.substitutionMin }}'</div>
                              <div><img src="/assets/icons/icn_salio.png" /></div>
                            </div>
                          </template>
                          <template v-if="data.substitutionIn">
                            <div
                              class="substitution-indicator margin-flag-top-subs"
                              :class="[sizeBrowser < 581 ? 'movil' : '']"
                            >
                              <div>{{ data.substitutionMin }}'</div>
                              <div><img src="/assets/icons/icn_ingreso.png" /></div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div
                        class="lineup-container-home__item-lineup__data-cont__position"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        {{ data.position }}
                      </div>
                      <div
                        class="lineup-container-home__item-lineup__data-cont__info-cont"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__img"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <div
                            class="lineup-container-home__item-lineup__data-cont__info-cont__img__cont"
                            :class="[sizeBrowser < 581 ? 'movil' : '']"
                            :style="{ backgroundImage: 'url(' + data.imgNationality + ')' }"
                          ></div>
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__abrev"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          {{ data.nationality }}
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__sustitution"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <!-- CONTAINER CARDS AND GOALS -->
                          <div class="cont-results-players">
                            <div class="text-bar-results" :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'">
                              <template v-if="data.goalsMin.length > 1">({{ data.goalsMin.length }})</template
                              ><template v-else>{{ data.goalsMin[0] }}'</template>
                            </div>
                            <div
                              class="gol-icon-item"
                              :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results no-margin-rigth"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.yellowcardsMin }}',
                            </div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.redcardsMin }}'
                            </div>
                            <div
                              class="redyellow-icon-item"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.yellowcardsMin }}'
                            </div>
                            <div
                              class="yellow-icon-item"
                              :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.redcardsMin }}'
                            </div>
                            <div
                              class="red-icon-item"
                              :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                          </div>
                          <!-- CONTAINER CARDS AND GOALS -->
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__num"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <div class="num__abs"><span>#</span>{{ data.shirt_number }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <div class="lineup-sep disabledTeam" :class="[sizeBrowser < 581 ? 'movil' : '']"></div>
            <div
              class="lineup-container-away"
              :class="[
                sizeBrowser < 581 ? (teamActive == 'away' ? 'visibleTeam' : 'disabledTeam') : '',
                sizeBrowser < 581 ? 'movil' : '',
              ]"
            >
              <template v-for="(data, key) in playersAway">
                <template v-if="data.played">
                  <div
                    :key="key + 'line_away'"
                    class="lineup-container-home__item-lineup"
                    :class="[sizeBrowser < 581 ? 'movil' : '']"
                  >
                    <div
                      class="lineup-container-home__item-lineup__image-cont"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    >
                      <div
                        class="lineup-container-home__item-lineup__image-cont__item"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <img :src="data.imgPlayer" width="53" alt="Jugador" />
                        <div
                          class="lineup-container-home__item-lineup__image-cont__item__team"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <img :src="data.imgTeam" alt="Jugador" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="lineup-container-home__item-lineup__data-cont"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    >
                      <div
                        class="lineup-container-home__item-lineup__data-cont__name"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <div class="name-list">
                          {{ data.abrevNamePlayer }}
                        </div>
                        <div>
                          <template v-if="data.substitutionOut">
                            <div
                              class="substitution-indicator margin-flag-top-subs"
                              :class="[sizeBrowser < 581 ? 'movil' : '']"
                            >
                              <div>{{ data.substitutionMin }}'</div>
                              <div><img src="/assets/icons/icn_salio.png" /></div>
                            </div>
                          </template>
                          <template v-if="data.substitutionIn">
                            <div
                              class="substitution-indicator margin-flag-top-subs"
                              :class="[sizeBrowser < 581 ? 'movil' : '']"
                            >
                              <div>{{ data.substitutionMin }}'</div>
                              <div><img src="/assets/icons/icn_ingreso.png" /></div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div
                        class="lineup-container-home__item-lineup__data-cont__position"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        {{ data.position }}
                      </div>
                      <div
                        class="lineup-container-home__item-lineup__data-cont__info-cont"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__img"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <div
                            class="lineup-container-home__item-lineup__data-cont__info-cont__img__cont"
                            :class="[sizeBrowser < 581 ? 'movil' : '']"
                            :style="{ backgroundImage: 'url(' + data.imgNationality + ')' }"
                          ></div>
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__abrev"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          {{ data.nationality }}
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__sustitution"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <!-- CONTAINER CARDS AND GOALS -->
                          <div class="cont-results-players">
                            <div class="text-bar-results" :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'">
                              <template v-if="data.goalsMin.length > 1">({{ data.goalsMin.length }})</template
                              ><template v-else>{{ data.goalsMin[0] }}'</template>
                            </div>
                            <div
                              class="gol-icon-item"
                              :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results no-margin-rigth"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.yellowcardsMin }}',
                            </div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.redcardsMin }}'
                            </div>
                            <div
                              class="redyellow-icon-item"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.yellowcardsMin }}'
                            </div>
                            <div
                              class="yellow-icon-item"
                              :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.redcardsMin }}'
                            </div>
                            <div
                              class="red-icon-item"
                              :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                          </div>
                          <!-- CONTAINER CARDS AND GOALS -->
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__num"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <div class="num__abs"><span>#</span>{{ data.shirt_number }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div class="lineup-players__pleca-lineup-container" v-if="subsTitle">
            <span>SUPLENTES</span>
          </div>
          <div class="lineup-players__lineup-container" :class="[sizeBrowser < 581 ? 'movil' : '']">
            <div
              class="lineup-container-home"
              :class="[
                sizeBrowser < 581 ? (teamActive == 'home' ? 'visibleTeam' : 'disabledTeam') : '',
                sizeBrowser < 581 ? 'movil' : '',
              ]"
            >
              <template v-for="(data, key) in playersHome">
                <template v-if="!data.played">
                  <div
                    :key="key + 'line_home_bench'"
                    class="lineup-container-home__item-lineup"
                    :class="[sizeBrowser < 581 ? 'movil' : '']"
                  >
                    <div
                      class="lineup-container-home__item-lineup__image-cont"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    >
                      <div
                        class="lineup-container-home__item-lineup__image-cont__item"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <img :src="data.imgPlayer" width="53" alt="Jugador" />
                        <div
                          class="lineup-container-home__item-lineup__image-cont__item__team"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <img :src="data.imgTeam" alt="Jugador" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="lineup-container-home__item-lineup__data-cont"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    >
                      <div
                        class="lineup-container-home__item-lineup__data-cont__name"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <div class="name-list">
                          {{ data.abrevNamePlayer }}
                        </div>
                        <div>
                          <template v-if="data.substitutionOut">
                            <div
                              class="substitution-indicator margin-flag-top-subs"
                              :class="[sizeBrowser < 581 ? 'movil' : '']"
                            >
                              <div>{{ data.substitutionMin }}'</div>
                              <div><img src="/assets/icons/icn_salio.png" /></div>
                            </div>
                          </template>
                          <template v-if="data.substitutionIn">
                            <div
                              class="substitution-indicator margin-flag-top-subs"
                              :class="[sizeBrowser < 581 ? 'movil' : '']"
                            >
                              <div>{{ data.substitutionMin }}'</div>
                              <div><img src="/assets/icons/icn_ingreso.png" /></div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div
                        class="lineup-container-home__item-lineup__data-cont__position"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        {{ data.position }}
                      </div>
                      <div
                        class="lineup-container-home__item-lineup__data-cont__info-cont"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__img"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <div
                            class="lineup-container-home__item-lineup__data-cont__info-cont__img__cont"
                            :class="[sizeBrowser < 581 ? 'movil' : '']"
                            :style="{ backgroundImage: 'url(' + data.imgNationality + ')' }"
                          ></div>
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__abrev"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          {{ data.nationality }}
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__sustitution"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <!-- CONTAINER CARDS AND GOALS -->
                          <div class="cont-results-players">
                            <div class="text-bar-results" :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'">
                              <template v-if="data.goalsMin.length > 1">({{ data.goalsMin.length }})</template
                              ><template v-else>{{ data.goalsMin[0] }}'</template>
                            </div>
                            <div
                              class="gol-icon-item"
                              :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results no-margin-rigth"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.yellowcardsMin }}',
                            </div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.redcardsMin }}'
                            </div>
                            <div
                              class="redyellow-icon-item"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.yellowcardsMin }}'
                            </div>
                            <div
                              class="yellow-icon-item"
                              :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.redcardsMin }}'
                            </div>
                            <div
                              class="red-icon-item"
                              :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                          </div>
                          <!-- CONTAINER CARDS AND GOALS -->
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__num"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <div class="num__abs"><span>#</span>{{ data.shirt_number }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-for="(data, index) in benchFillHome">
                <div
                  :key="'line_home_bench-3' + index"
                  class="lineup-container-home__item-lineup"
                  style="border-bottom: none;"
                  :class="[sizeBrowser < 581 ? 'movil' : '']"
                >
                  <div
                    class="lineup-container-home__item-lineup__image-cont"
                    :class="[sizeBrowser < 581 ? 'movil' : '']"
                  >
                    <div
                      class="lineup-container-home__item-lineup__image-cont__item"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    ></div>
                  </div>
                </div>
              </template>
            </div>
            <div
              class="lineup-sep"
              :class="[
                sizeBrowser < 581 ? (teamActive == 'away' ? 'visibleTeam' : 'disabledTeam') : '',
                sizeBrowser < 581 ? 'movil' : '',
              ]"
            ></div>
            <div
              class="lineup-container-away"
              :class="[
                sizeBrowser < 581 ? (teamActive == 'away' ? 'visibleTeam' : 'disabledTeam') : '',
                sizeBrowser < 581 ? 'movil' : '',
              ]"
            >
              <template v-for="(data, key) in playersAway">
                <template v-if="!data.played">
                  <div
                    :key="key + 'line_away_bench'"
                    class="lineup-container-home__item-lineup"
                    :class="[sizeBrowser < 581 ? 'movil' : '']"
                  >
                    <div
                      class="lineup-container-home__item-lineup__image-cont"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    >
                      <div
                        class="lineup-container-home__item-lineup__image-cont__item"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <img :src="data.imgPlayer" width="53" alt="Jugador" />
                        <div
                          class="lineup-container-home__item-lineup__image-cont__item__team"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <img :src="data.imgTeam" alt="Jugador" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="lineup-container-home__item-lineup__data-cont"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    >
                      <div
                        class="lineup-container-home__item-lineup__data-cont__name"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <div class="name-list">
                          {{ data.abrevNamePlayer }}
                        </div>
                        <div>
                          <template v-if="data.substitutionOut">
                            <div
                              class="substitution-indicator margin-flag-top-subs"
                              :class="[sizeBrowser < 581 ? 'movil' : '']"
                            >
                              <div>{{ data.substitutionMin }}'</div>
                              <div><img src="/assets/icons/icn_salio.png" /></div>
                            </div>
                          </template>
                          <template v-if="data.substitutionIn">
                            <div
                              class="substitution-indicator margin-flag-top-subs"
                              :class="[sizeBrowser < 581 ? 'movil' : '']"
                            >
                              <div>{{ data.substitutionMin }}'</div>
                              <div><img src="/assets/icons/icn_ingreso.png" /></div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div
                        class="lineup-container-home__item-lineup__data-cont__position"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        {{ data.position }}
                      </div>
                      <div
                        class="lineup-container-home__item-lineup__data-cont__info-cont"
                        :class="[sizeBrowser < 581 ? 'movil' : '']"
                      >
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__img"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <div
                            class="lineup-container-home__item-lineup__data-cont__info-cont__img__cont"
                            :class="[sizeBrowser < 581 ? 'movil' : '']"
                            :style="{ backgroundImage: 'url(' + data.imgNationality + ')' }"
                          ></div>
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__abrev"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          {{ data.nationality }}
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__sustitution"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <!-- CONTAINER CARDS AND GOALS -->
                          <div class="cont-results-players">
                            <div class="text-bar-results" :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'">
                              <template v-if="data.goalsMin.length > 1">({{ data.goalsMin.length }})</template
                              ><template v-else>{{ data.goalsMin[0] }}'</template>
                            </div>
                            <div
                              class="gol-icon-item"
                              :class="data.goals > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results no-margin-rigth"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.yellowcardsMin }}',
                            </div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.redcardsMin }}'
                            </div>
                            <div
                              class="redyellow-icon-item"
                              :class="data.yellowcards > 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.yellowcardsMin }}'
                            </div>
                            <div
                              class="yellow-icon-item"
                              :class="data.yellowcards > 0 && data.redcards == 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                            <div
                              class="text-bar-results"
                              :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            >
                              {{ data.redcardsMin }}'
                            </div>
                            <div
                              class="red-icon-item"
                              :class="data.yellowcards == 0 && data.redcards > 0 ? 'cardsDisplay' : 'cardsNotDisplay'"
                            ></div>
                          </div>
                          <!-- CONTAINER CARDS AND GOALS -->
                        </div>
                        <div
                          class="lineup-container-home__item-lineup__data-cont__info-cont__num"
                          :class="[sizeBrowser < 581 ? 'movil' : '']"
                        >
                          <div class="num__abs"><span>#</span>{{ data.shirt_number }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-for="(data, index) in benchFillAway">
                <div
                  :key="'line_home_bench-3' + index"
                  class="lineup-container-home__item-lineup"
                  style="border-bottom: none;"
                  :class="[sizeBrowser < 581 ? 'movil' : '']"
                >
                  <div
                    class="lineup-container-home__item-lineup__image-cont"
                    :class="[sizeBrowser < 581 ? 'movil' : '']"
                  >
                    <div
                      class="lineup-container-home__item-lineup__image-cont__item"
                      :class="[sizeBrowser < 581 ? 'movil' : '']"
                    ></div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { connectSocket, connectChannel } from '@/utils/socketConnection';
import { mapGetters, mapState } from 'vuex';
let calendarResizeObserver = null;
export default {
  name: 'lineupPlayers',
  props: {
    game: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      url: 'https://tj4cepyjc4.execute-api.us-west-2.amazonaws.com/prod/lineups/',
      urlMXM: 'https://al2nv2dmkc.execute-api.us-west-2.amazonaws.com/prod/mxm/',
      retries: 3,
      teamActive: 'home',
      subsActive: false,
      playersHome: [],
      gamesContainerWidth: 0,
      playersAway: [],
      subsTitle: false,
      uniformHome: '',
      uniformHomeG: '',
      uniformAway: '',
      uniformAwayG: '',
      dataPlayersHome: [],
      dataPlayersAway: [],
      homeTeamLogo: '',
      awayTeamLogo: '',
      homeTeamName: '',
      awayTeamName: '',
      homeTeamAbrev: '',
      awayTeamAbrev: '',
      home_team_id: 0,
      updateComponentAway: 0,
      away_team_id: 0,
      substitutionModal: {
        min: '',
        in: {
          playerImg: '',
          teamImg: '',
          nationalityimg: '',
          name: '',
          position: '',
          shirt_number: '',
        },
        out: {
          playerImg: '',
          teamImg: '',
          nationalityimg: '',
          name: '',
          position: '',
          shirt_number: '',
        },
      },
      movil: false,
      positions: [
        { name: 'Portero' },
        { name: 'Defensa central' },
        { name: 'Lateral por izquierda' },
        { name: 'Lateral por derecha' },
        { name: 'Volante por izquierda' },
        { name: 'Volante por derecha' },
        { name: 'Volante defensivo' },
        { name: 'Volante ofensivo' },
        { name: 'Delantero' },
        { name: 'Desconocida' },
      ],
    };
  },
  computed: {
    ...mapGetters('nextGeneral', ['homeTeam', 'visitingTeam']),
    ...mapState('nextGeneral', ['isLive', 'gameForAnalysis', 'showLiveData']),
    sizeBrowser() {
      return this.gamesContainerWidth;
    },
    numPlayer() {
      return Number(this.playersAway.length) + Number(this.playersHome.length);
    },
    benchFillHome() {
      if (this.playersHome.length < this.playersAway.length) {
        return this.playersAway.length - this.playersHome.length;
      } else {
        return 0;
      }
    },
    benchFillAway() {
      if (this.playersAway.length < this.playersHome.length) {
        return this.playersHome.length - this.playersAway.length;
      } else {
        return 0;
      }
    },
  },
  async mounted() {
    setTimeout(() => {
      this.setUpResizeObserver();
    }, 100);
    if (this.game || this.$route.params.gameId) {
      try {
        this.getLineUp(this.$route.params.gameId);
      } catch (e) {
        throw new Error(e);
      }
    }
  },
  watch: {
    '$socket.connected': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.getDataSocket();
        }
      },
    },
    game: {
      immediate: true,
      handler: function(newValue) {
        try {
          if (newValue) {
            this.getLineUp(newValue);
          }
        } catch (e) {
          throw new Error(e);
        }
      },
    },
  },
  sockets: {
    new_bench(channelName, benchEvent) {
      if (benchEvent[this.home_team_id]) {
        this.playersHome = this.playersHome.filter(function(el) {
          return !el.isbench;
        });
        for (let index = 0; index < benchEvent[this.home_team_id].length; index++) {
          if (!benchEvent[this.home_team_id][index].isTitular) {
            this.subsTitle = true;
            var NameArrayHomeBench = benchEvent[this.home_team_id][index].playerName.split(',');
            var lastArrayNameBench = NameArrayHomeBench[0].split(' ');
            var ArrayNameBench = NameArrayHomeBench[1].trim().split(' ');
            var lastNameHomeBench = '';
            if (lastArrayNameBench.length == 2 || lastArrayNameBench.length == 1) {
              if (lastArrayNameBench[0].length < 4) {
                lastNameHomeBench = lastArrayNameBench[0] + ' ' + lastArrayNameBench[1];
              } else {
                lastNameHomeBench = lastArrayNameBench[0];
              }
            } else {
              if (lastArrayNameBench.length == 3) {
                lastNameHomeBench = lastArrayNameBench[0] + ' ' + lastArrayNameBench[1];
              } else {
                if (lastArrayNameBench.length == 4) {
                  lastNameHomeBench = lastArrayNameBench[1] + ' ' + lastArrayNameBench[2] + ' ' + lastArrayNameBench[3];
                }
              }
            }
            var nameHomeBench =
              ArrayNameBench[ArrayNameBench.length - 1] != ''
                ? ArrayNameBench[ArrayNameBench.length - 1].charAt(0)
                : ArrayNameBench[ArrayNameBench.length - 2]
                ? ArrayNameBench[ArrayNameBench.length - 2].charAt(0)
                : '';
            lastNameHomeBench = lastNameHomeBench.replace(',', '');
            var abrevNamePlayerHomeBench = nameHomeBench + '. ' + lastNameHomeBench;
            var indexPlayerDataHomeBench = this.dataPlayersHome.findIndex(
              player => player.player_id == benchEvent[this.home_team_id][index].playerID,
            );
            this.playersHome.push({
              played: false,
              name: benchEvent[this.home_team_id][index].playerName,
              position:
                indexPlayerDataHomeBench != -1
                  ? this.positions[this.dataPlayersHome[indexPlayerDataHomeBench].position_id - 1].name
                  : '',
              position_id: this.dataPlayersHome[indexPlayerDataHomeBench].position_id,
              imgPlayer:
                'https://az755631.vo.msecnd.net/players-profile/' +
                benchEvent[this.home_team_id][index].playerID +
                '.png',
              nationality:
                indexPlayerDataHomeBench != -1
                  ? this.dataPlayersHome[indexPlayerDataHomeBench].nationality
                    ? this.dataPlayersHome[indexPlayerDataHomeBench].nationality.substring(0, 3)
                    : ''
                  : '',
              imgNationality:
                indexPlayerDataHomeBench != -1 ? this.dataPlayersHome[indexPlayerDataHomeBench].nationality_photo : '',
              substitutionIn: false,
              substitutionOut: false,
              abrevNamePlayer: abrevNamePlayerHomeBench,
              imgTeam: 'https://az755631.vo.msecnd.net/teams-80/' + this.home_team_id + '.png',
              substitutionMin: '',
              substitutionDataID: '',
              shirt_number: benchEvent[this.home_team_id][index].jerseyNumber,
              positionX: '',
              positionY: '',
              positionXOrigin: '',
              positionYOrigin: '',
              playerId: benchEvent[this.home_team_id][index].playerID,
              goals: 0,
              yellowcards: 0,
              redcards: 0,
              goalsMin: [],
              yellowcardsMin: 0,
              redcardsMin: 0,
              idyellowcards: 0,
              idredcards: 0,
              idsGoals: [],
              idSubstitution: 0,
              isbench: true,
            });
          }
        }
        this.playersHome.sort((a, b) => (a.position_id > b.position_id ? 1 : b.position_id > a.position_id ? -1 : 0));
      }

      if (benchEvent[this.away_team_id]) {
        this.playersAway = this.playersAway.filter(function(el) {
          return !el.isbench;
        });
        for (let index = 0; index < benchEvent[this.away_team_id].length; index++) {
          if (!benchEvent[this.away_team_id][index].isTitular) {
            this.subsTitle = true;
            var NameArrayAwayBench = benchEvent[this.away_team_id][index].playerName.split(',');
            var lastArrayNameBenchAway = NameArrayAwayBench[0].split(' ');
            var ArrayNameBenchAway = NameArrayHomeBench[1].trim().split(' ');
            var lastNameAwayBench = '';
            if (lastArrayNameBenchAway.length == 2 || lastArrayNameBenchAway.length == 1) {
              if (lastArrayNameBenchAway[0].length < 4) {
                lastNameAwayBench = lastArrayNameBenchAway[0] + ' ' + lastArrayNameBenchAway[1];
              } else {
                lastNameAwayBench = lastArrayNameBenchAway[0];
              }
            } else {
              if (lastArrayNameBenchAway.length == 3) {
                lastNameAwayBench = lastArrayNameBenchAway[0] + ' ' + lastArrayNameBenchAway[1];
              } else {
                if (lastArrayNameBenchAway.length == 4) {
                  lastNameAwayBench =
                    lastArrayNameBenchAway[1] + ' ' + lastArrayNameBenchAway[2] + ' ' + lastArrayNameBenchAway[3];
                }
              }
            }
            var nameHomeBenchAway =
              ArrayNameBenchAway[ArrayNameBenchAway.length - 1] != ''
                ? ArrayNameBenchAway[ArrayNameBenchAway.length - 1].charAt(0)
                : ArrayNameBenchAway[ArrayNameBenchAway.length - 2]
                ? ArrayNameBenchAway[ArrayNameBenchAway.length - 2].charAt(0)
                : '';
            lastNameAwayBench = lastNameAwayBench.replace(',', '');
            var abrevNamePlayerAwayBench = nameHomeBenchAway + '. ' + lastNameAwayBench;
            var indexPlayerDataAwayBench = this.dataPlayersAway.findIndex(
              player => player.player_id == benchEvent[this.away_team_id][index].playerID,
            );
            this.playersAway.push({
              played: false,
              name: benchEvent[this.away_team_id][index].playerName,
              position:
                indexPlayerDataAwayBench != -1
                  ? this.positions[this.dataPlayersAway[indexPlayerDataAwayBench].position_id - 1].name
                  : '',
              position_id: this.dataPlayersAway[indexPlayerDataAwayBench].position_id,
              imgPlayer:
                'https://az755631.vo.msecnd.net/players-profile/' +
                benchEvent[this.away_team_id][index].playerID +
                '.png',
              nationality:
                indexPlayerDataAwayBench != -1
                  ? this.dataPlayersAway[indexPlayerDataAwayBench].nationality
                    ? this.dataPlayersAway[indexPlayerDataAwayBench].nationality.substring(0, 3)
                    : ''
                  : '',
              imgNationality:
                indexPlayerDataAwayBench != -1 ? this.dataPlayersAway[indexPlayerDataAwayBench].nationality_photo : '',
              substitutionIn: false,
              substitutionOut: false,
              abrevNamePlayer: abrevNamePlayerAwayBench,
              imgTeam: 'https://az755631.vo.msecnd.net/teams-80/' + this.away_team_id + '.png',
              substitutionMin: '',
              substitutionDataID: '',
              shirt_number: benchEvent[this.away_team_id][index].jerseyNumber,
              positionX: '',
              positionY: '',
              positionXOrigin: '',
              positionYOrigin: '',
              playerId: benchEvent[this.away_team_id][index].playerID,
              goals: 0,
              yellowcards: 0,
              redcards: 0,
              goalsMin: [],
              yellowcardsMin: 0,
              redcardsMin: 0,
              idyellowcards: 0,
              idredcards: 0,
              idsGoals: [],
              idSubstitution: 0,
              isbench: true,
            });
          }
        }
        this.playersAway.sort((a, b) => (a.position_id > b.position_id ? 1 : b.position_id > a.position_id ? -1 : 0));
      }
    },
    new_lineup(channelName, gameEvent) {
      if (gameEvent.team_id == gameEvent.home_team.team_id) {
        if (this.playersHome.length == 0) {
          this.dataPlayersHome = gameEvent.home_team.players;

          for (let index = 0; index < gameEvent.data.length; index++) {
            this.home_team_id = gameEvent.team_id;
            this.imageExists('https://az755631.vo.msecnd.net/jersey-teams/' + gameEvent.team_id + '.svg', 'home', 1);
            this.imageExists('https://az755631.vo.msecnd.net/jersey-teams/' + gameEvent.team_id + '_g.svg', 'home', 0);
            var NameArrayHome = gameEvent.data[index].player_name.split(',');
            var lastArrayName = NameArrayHome[0].split(' ');
            var ArrayName = NameArrayHome[1].trim().split(' ');
            var lastNameHome = '';
            if (lastArrayName.length == 2 || lastArrayName.length == 1) {
              if (lastArrayName[0].length < 4) {
                lastNameHome = lastArrayName[0] + ' ' + lastArrayName[1];
              } else {
                lastNameHome = lastArrayName[0];
              }
            } else {
              if (lastArrayName.length == 3) {
                lastNameHome = lastArrayName[0] + ' ' + lastArrayName[1];
              } else {
                if (lastArrayName.length == 4) {
                  lastNameHome = lastArrayName[1] + ' ' + lastArrayName[2] + ' ' + lastArrayName[3];
                }
              }
            }
            var nameHome =
              ArrayName[ArrayName.length - 1] != ''
                ? ArrayName[ArrayName.length - 1].charAt(0)
                : ArrayName[ArrayName.length - 2]
                ? ArrayName[ArrayName.length - 2].charAt(0)
                : '';
            lastNameHome = lastNameHome.replace(',', '');
            var abrevNamePlayerHome = nameHome + '. ' + lastNameHome;

            var newCooHome = gameEvent.data[index].x;
            var positionConvertHome = (newCooHome * 50) / 100;
            var indexPlayerDataHome = gameEvent.home_team.players.findIndex(
              player => player.player_id == gameEvent.data[index].player_id,
            );
            this.playersHome.push({
              played: true,
              name: gameEvent.data[index].player_name,
              position:
                indexPlayerDataHome != -1
                  ? this.positions[gameEvent.home_team.players[indexPlayerDataHome].position_id - 1].name
                  : '',
              position_id: gameEvent.home_team.players[indexPlayerDataHome].position_id,
              imgPlayer: 'https://az755631.vo.msecnd.net/players-profile/' + gameEvent.data[index].player_id + '.png',
              nationality:
                indexPlayerDataHome != -1
                  ? gameEvent.home_team.players[indexPlayerDataHome].nationality
                    ? gameEvent.home_team.players[indexPlayerDataHome].nationality.substring(0, 3)
                    : ''
                  : '',
              imgNationality:
                indexPlayerDataHome != -1 ? gameEvent.home_team.players[indexPlayerDataHome].nationality_photo : '',
              substitutionIn: false,
              substitutionOut: false,
              abrevNamePlayer: abrevNamePlayerHome,
              imgTeam: 'https://az755631.vo.msecnd.net/teams-80/' + gameEvent.team_id + '.png',
              substitutionMin: '',
              substitutionDataID: '',
              shirt_number: gameEvent.data[index].jersey_number,
              positionX: gameEvent.data[index].y - 13,
              positionY: positionConvertHome * 0.9,
              positionXOrigin: gameEvent.data[index].y - 17,
              positionYOrigin: gameEvent.data[index].x - 2,
              playerId: gameEvent.data[index].player_id,
              goals: 0,
              yellowcards: 0,
              redcards: 0,
              goalsMin: [],
              yellowcardsMin: 0,
              redcardsMin: 0,
              idyellowcards: 0,
              idredcards: 0,
              idsGoals: [],
              idSubstitution: 0,
              isbench: false,
              id_team: gameEvent.team_id,
            });
            this.homeTeamLogo = 'https://az755631.vo.msecnd.net/teams-80/' + gameEvent.team_id + '.png';
          }

          this.playersHome.sort((a, b) => (a.position_id > b.position_id ? 1 : b.position_id > a.position_id ? -1 : 0));
        }
      } else {
        if (this.playersAway.length == 0) {
          this.dataPlayersAway = gameEvent.away_team.players;
          for (let index = 0; index < gameEvent.data.length; index++) {
            this.away_team_id = gameEvent.team_id;
            this.imageExists('https://az755631.vo.msecnd.net/jersey-teams/' + gameEvent.team_id + '.svg', 'away', 1);
            this.imageExists('https://az755631.vo.msecnd.net/jersey-teams/' + gameEvent.team_id + '_g.svg', 'away', 0);
            var NameArrayAway = gameEvent.data[index].player_name.split(',');
            var lastArrayNameAway = NameArrayAway[0].split(' ');
            var ArrayNameAway = NameArrayAway[1].trim().split(' ');
            var lastNameAway = '';
            if (lastArrayNameAway.length == 2 || lastArrayNameAway.length == 1) {
              if (lastArrayNameAway[0].length < 4) {
                lastNameAway = lastArrayNameAway[0] + ' ' + lastArrayNameAway[1];
              } else {
                lastNameAway = lastArrayNameAway[0];
              }
            } else {
              if (lastArrayNameAway.length == 3) {
                lastNameAway = lastArrayNameAway[0] + ' ' + lastArrayNameAway[1];
              } else {
                if (lastArrayNameAway.length == 4) {
                  lastNameAway = lastArrayNameAway[1] + ' ' + lastArrayNameAway[2] + ' ' + lastArrayNameAway[3];
                }
              }
            }
            var nameAway =
              ArrayNameAway[ArrayNameAway.length - 1] != ''
                ? ArrayNameAway[ArrayNameAway.length - 1].charAt(0)
                : ArrayNameAway[ArrayNameAway.length - 2]
                ? ArrayNameAway[ArrayNameAway.length - 2].charAt(0)
                : '';
            lastNameAway = lastNameAway.replace(',', '');
            var abrevNamePlayerAway = nameAway + '. ' + lastNameAway;

            var newCooAway = gameEvent.data[index].x;

            var positionConvertAway = 100 - ((100 - newCooAway) * 50) / 100;
            positionConvertAway = positionConvertAway * 0.9;
            var indexPlayerDataAway = gameEvent.away_team.players.findIndex(
              player => player.player_id == gameEvent.data[index].player_id,
            );
            this.playersAway.push({
              played: true,
              name: gameEvent.data[index].player_name,
              position:
                indexPlayerDataAway != -1
                  ? this.positions[gameEvent.away_team.players[indexPlayerDataAway].position_id - 1].name
                  : '',
              position_id: gameEvent.away_team.players[indexPlayerDataAway].position_id,
              imgPlayer: 'https://az755631.vo.msecnd.net/players-profile/' + gameEvent.data[index].player_id + '.png',
              nationality:
                indexPlayerDataAway != -1
                  ? gameEvent.away_team.players[indexPlayerDataAway].nationality
                    ? gameEvent.away_team.players[indexPlayerDataAway].nationality.substring(0, 3)
                    : ''
                  : '',
              imgNationality:
                indexPlayerDataAway != -1 ? gameEvent.away_team.players[indexPlayerDataAway].nationality_photo : '',
              substitutionIn: false,
              substitutionOut: false,
              abrevNamePlayer: abrevNamePlayerAway,
              imgTeam: 'https://az755631.vo.msecnd.net/teams-80/' + gameEvent.team_id + '.png',
              substitutionMin: '',
              substitutionDataID: '',
              shirt_number: gameEvent.data[index].jersey_number,
              positionX: gameEvent.data[index].y - 13,
              positionY: positionConvertAway + 2,
              positionXOrigin: gameEvent.data[index].y - 17,
              positionYOrigin: gameEvent.data[index].x - 7,
              playerId: gameEvent.data[index].player_id,
              goals: 0,
              yellowcards: 0,
              redcards: 0,
              goalsMin: [],
              yellowcardsMin: 0,
              redcardsMin: 0,
              idyellowcards: 0,
              idredcards: 0,
              idsGoals: [],
              idSubstitution: 0,
              isbench: false,
              id_team: gameEvent.team_id,
            });
            this.awayTeamLogo = 'https://az755631.vo.msecnd.net/teams-80/' + gameEvent.team_id + '.png';
          }
          this.playersAway.sort((a, b) => (a.position_id > b.position_id ? 1 : b.position_id > a.position_id ? -1 : 0));
        }
      }
    },
    update_mxm(channelName, updateGameEvent) {
      var indexGoalF = -1;
      var indexGoalP = -1;
      if (this.playersHome.length > 0) {
        var playIndexHomeRedCard = this.playersHome.findIndex(player => player.idredcards == updateGameEvent.play_id);
        var playIndexHomeYellowCard = this.playersHome.findIndex(
          player => player.idyellowcards == updateGameEvent.play_id,
        );
        var playIndexHomeSubstitution = this.playersHome.findIndex(
          player => player.idSubstitution == updateGameEvent.play_id,
        );
        for (let indexH = 0; indexH < this.playersHome.length; indexH++) {
          var playIndexHomeGoals = this.playersHome[indexH].idsGoals.findIndex(
            goalMin => goalMin == updateGameEvent.play_id,
          );
          if (playIndexHomeGoals != -1) {
            indexGoalF = playIndexHomeGoals;
            indexGoalP = indexH;
          }
        }
        if (playIndexHomeRedCard != -1) {
          this.resetPlayUpdate('RedCard', 'Home', playIndexHomeRedCard, indexGoalP);
        }
        if (playIndexHomeYellowCard != -1) {
          this.resetPlayUpdate('YellowCard', 'Home', playIndexHomeYellowCard, indexGoalP);
        }
        if (playIndexHomeSubstitution != -1) {
          this.resetPlayUpdate('Substitution', 'Home', playIndexHomeSubstitution, indexGoalP);
        }
        if (indexGoalF != -1) {
          this.resetPlayUpdate('Goals', 'Home', indexGoalF, indexGoalP);
        }
      }
      if (this.playersAway.length > 0) {
        var playIndexHAwayRedCard = this.playersAway.findIndex(player => player.idredcards == updateGameEvent.play_id);
        var playIndexAwayYellowCard = this.playersAway.findIndex(
          player => player.idyellowcards == updateGameEvent.play_id,
        );
        var playIndexAwaySubstitution = this.playersAway.findIndex(
          player => player.idSubstitution == updateGameEvent.play_id,
        );
        for (let indexA = 0; indexA < this.playersAway.length; indexA++) {
          var playIndexAwayGoals = this.playersAway[indexA].idsGoals.findIndex(
            goalMin => goalMin == updateGameEvent.play_id,
          );
          if (playIndexAwayGoals != -1) {
            indexGoalF = playIndexAwayGoals;
            indexGoalP = indexA;
          }
        }
        if (playIndexHAwayRedCard != -1) {
          this.resetPlayUpdate('RedCard', 'Away', playIndexHAwayRedCard, indexGoalP);
        }
        if (playIndexAwayYellowCard != -1) {
          this.resetPlayUpdate('YellowCard', 'Away', playIndexAwayYellowCard, indexGoalP);
        }
        if (playIndexAwaySubstitution != -1) {
          this.resetPlayUpdate('Substitution', 'Away', playIndexAwaySubstitution, indexGoalP);
        }
        if (indexGoalF != -1) {
          this.resetPlayUpdate('Goals', 'Away', indexGoalF, indexGoalP);
        }
      }
      if (updateGameEvent.event_type_id == 3) {
        if (updateGameEvent.team_id == this.home_team_id) {
          if (this.playersHome.length > 0) {
            var idsPlayers = updateGameEvent.player_id.split('@');
            var indexChangeHomeOut = this.playersHome.findIndex(player => player.playerId == idsPlayers[0]);
            var indexChangeHomeIn = this.playersHome.findIndex(player => player.playerId == idsPlayers[1]);
            if (indexChangeHomeOut != -1) {
              this.playersHome[indexChangeHomeOut].idSubstitution = updateGameEvent.play_id;
              this.playersHome[indexChangeHomeOut].substitutionOut = true;
              this.playersHome[indexChangeHomeOut].substitutionMin = updateGameEvent.minute;
              this.playersHome[indexChangeHomeOut].substitutionDataID =
                updateGameEvent.team_id + '@' + updateGameEvent.player_id;
            }
            if (indexChangeHomeIn != -1) {
              this.playersHome[indexChangeHomeIn].idSubstitution = updateGameEvent.play_id;
              this.playersHome[indexChangeHomeIn].substitutionIn = true;
              this.playersHome[indexChangeHomeIn].substitutionMin = updateGameEvent.minute;
              this.playersHome[indexChangeHomeIn].substitutionDataID =
                updateGameEvent.team_id + '@' + updateGameEvent.player_id;
            }
          }
        } else {
          if (this.playersAway.length > 0) {
            var idsPlayersAway = updateGameEvent.player_id.split('@');
            var indexChangeAwayOut = this.playersAway.findIndex(player => player.playerId == idsPlayersAway[0]);
            var indexChangeAwayIn = this.playersAway.findIndex(player => player.playerId == idsPlayersAway[1]);
            if (indexChangeHomeOut != -1) {
              this.playersAway[indexChangeAwayOut].idSubstitution = updateGameEvent.play_id;
              this.playersAway[indexChangeAwayOut].substitutionOut = true;
              this.playersAway[indexChangeAwayOut].substitutionMin = updateGameEvent.minute;
              this.playersAway[indexChangeAwayOut].substitutionDataID =
                updateGameEvent.team_id + '@' + updateGameEvent.player_id;
            }
            if (indexChangeAwayIn != -1) {
              this.playersAway[indexChangeAwayIn].idSubstitution = updateGameEvent.play_id;
              this.playersAway[indexChangeAwayIn].substitutionIn = true;
              this.playersAway[indexChangeAwayIn].substitutionMin = updateGameEvent.minute;
              this.playersAway[indexChangeAwayIn].substitutionDataID =
                updateGameEvent.team_id + '@' + updateGameEvent.player_id;
            }
          }
        }
      }
      if (updateGameEvent.event_type_id == 1) {
        if (updateGameEvent.team_id == this.home_team_id) {
          if (this.playersHome.length > 0) {
            var indexPlayerGoalHome = this.playersHome.findIndex(
              player => player.playerId == updateGameEvent.player_id,
            );
            if (indexPlayerGoalHome != -1) {
              this.playersHome[indexPlayerGoalHome].goals += 1;
              this.playersHome[indexPlayerGoalHome].goalsMin.push(updateGameEvent.minute);
              this.playersHome[indexPlayerGoalHome].idsGoals.push(updateGameEvent.play_id);
            }
          }
        } else {
          if (this.playersAway.length > 0) {
            var indexPlayerGoalAway = this.playersAway.findIndex(
              player => player.playerId == updateGameEvent.player_id,
            );
            if (indexPlayerGoalAway != -1) {
              this.playersAway[indexPlayerGoalAway].goals += 1;
              this.playersAway[indexPlayerGoalAway].goalsMin.push(updateGameEvent.minute);
              this.playersAway[indexPlayerGoalAway].idsGoals.push(updateGameEvent.play_id);
            }
          }
        }
      }
      if (updateGameEvent.event_type_id == 2) {
        if (updateGameEvent.category_id == 8) {
          if (updateGameEvent.team_id == this.home_team_id) {
            if (this.playersHome.length > 0) {
              var indexPlayerYellowHome = this.playersHome.findIndex(
                player => player.playerId == updateGameEvent.player_id,
              );
              if (indexPlayerYellowHome != -1) {
                this.playersHome[indexPlayerYellowHome].yellowcards += 1;
                this.playersHome[indexPlayerYellowHome].yellowcardsMin = updateGameEvent.minute;
                this.playersHome[indexPlayerYellowHome].idyellowcards = updateGameEvent.play_id;
              }
            }
          } else {
            if (this.playersAway.length > 0) {
              var indexPlayerYellowAway = this.playersAway.findIndex(
                player => player.playerId == updateGameEvent.player_id,
              );
              if (indexPlayerYellowAway != -1) {
                this.playersAway[indexPlayerYellowAway].yellowcards += 1;
                this.playersAway[indexPlayerYellowAway].yellowcardsMin = updateGameEvent.minute;
                this.playersAway[indexPlayerYellowAway].idyellowcards = updateGameEvent.play_id;
              }
            }
          }
        }
        if (updateGameEvent.category_id == 9) {
          if (updateGameEvent.team_id == this.home_team_id) {
            if (this.playersHome.length > 0) {
              var indexPlayerRedHome = this.playersHome.findIndex(
                player => player.playerId == updateGameEvent.player_id,
              );
              if (indexPlayerRedHome != -1) {
                this.playersHome[indexPlayerRedHome].redcards += 1;
                this.playersHome[indexPlayerRedHome].redcardsMin = updateGameEvent.minute;
                this.playersHome[indexPlayerRedHome].idredcards = updateGameEvent.play_id;
              }
            }
          } else {
            if (this.playersAway.length > 0) {
              var indexPlayerRedAway = this.playersAway.findIndex(
                player => player.playerId == updateGameEvent.player_id,
              );
              if (indexPlayerRedAway != -1) {
                this.playersAway[indexPlayerRedAway].redcards += 1;
                this.playersAway[indexPlayerRedAway].redcardsMin = updateGameEvent.minute;
                this.playersAway[indexPlayerRedAway].idredcards = updateGameEvent.play_id;
              }
            }
          }
        }
      }
    },
    delete_mxm(channelName, deleteGameEvent) {
      if (this.playersHome.length > 0) {
        for (let index = 0; index < this.playersHome.length; index++) {
          if (this.playersHome[index].idSubstitution == deleteGameEvent.play_id) {
            this.playersHome[index].substitutionOut = false;
            this.playersHome[index].substitutionIn = false;
            this.playersHome[index].substitutionMin = '';
            this.playersHome[index].substitutionDataID = '';
            this.playersHome[index].idSubstitution = 0;
          }
        }
      }
      if (this.playersAway.length > 0) {
        for (let indexAS = 0; indexAS < this.playersAway.length; indexAS++) {
          if (this.playersAway[indexAS].idSubstitution == deleteGameEvent.play_id) {
            this.playersAway[indexAS].substitutionOut = false;
            this.playersAway[indexAS].substitutionIn = false;
            this.playersAway[indexAS].substitutionMin = '';
            this.playersAway[indexAS].substitutionDataID = '';
            this.playersAway[indexAS].idSubstitution = 0;
          }
        }
      }
      if (this.playersHome.length > 0) {
        for (let indexGH = 0; indexGH < this.playersHome.length; indexGH++) {
          const indexGoal = this.playersHome[indexGH].idsGoals.findIndex(goalMin => goalMin == deleteGameEvent.play_id);
          if (indexGoal != -1) {
            this.playersHome[indexGH].goals = this.playersHome[indexGH].goals - 1;
            this.playersHome[indexGH].goalsMin.splice(indexGoal, 1);
            this.playersHome[indexGH].idsGoals.splice(indexGoal, 1);
          }
        }
      }
      if (this.playersAway.length > 0) {
        for (let indexGA = 0; indexGA < this.playersAway.length; indexGA++) {
          const indexGoal = this.playersAway[indexGA].idsGoals.findIndex(goalMin => goalMin == deleteGameEvent.play_id);
          if (indexGoal != -1) {
            this.playersAway[indexGA].goals = this.playersAway[indexGA].goals - 1;
            this.playersAway[indexGA].goalsMin.splice(indexGoal, 1);
            this.playersAway[indexGA].idsGoals.splice(indexGoal, 1);
          }
        }
      }
      if (this.playersHome.length > 0) {
        for (let indexAH = 0; indexAH < this.playersAway.length; indexAH++) {
          if (this.playersHome[indexAH].idyellowcards == deleteGameEvent.play_id) {
            this.playersHome[indexAH].yellowcards = 0;
            this.playersHome[indexAH].yellowcardsMin = 0;
            this.playersHome[indexAH].idyellowcards = 0;
          }
        }
      }
      if (this.playersAway.length > 0) {
        for (let indexAA = 0; indexAA < this.playersAway.length; indexAA++) {
          if (this.playersAway[indexAA].idyellowcards == deleteGameEvent.play_id) {
            this.playersAway[indexAA].yellowcards = 0;
            this.playersAway[indexAA].yellowcardsMin = 0;
            this.playersAway[indexAA].idyellowcards = 0;
          }
        }
      }
      if (this.playersHome.length > 0) {
        for (let indexRH = 0; indexRH < this.playersAway.length; indexRH++) {
          if (this.playersHome[indexRH].idredcards == deleteGameEvent.play_id) {
            this.playersHome[indexRH].redcards = 0;
            this.playersHome[indexRH].redcardsMin = 0;
            this.playersHome[indexRH].idredcards = 0;
          }
        }
      }
      if (this.playersAway.length > 0) {
        for (let indexRA = 0; indexRA < this.playersAway.length; indexRA++) {
          if (this.playersAway[indexRA].idredcards == deleteGameEvent.play_id) {
            this.playersAway[indexRA].redcards = 0;
            this.playersAway[indexRA].redcardsMin = 0;
            this.playersAway[indexRA].idredcards = 0;
          }
        }
      }
    },
    new_mxm(channelName, gameEvent) {
      if (gameEvent.event_type_id == 3) {
        if (gameEvent.team_id == this.home_team_id) {
          if (this.playersHome.length > 0) {
            var idsPlayers = gameEvent.player_id.split('@');
            var indexChangeHomeOut = this.playersHome.findIndex(player => player.playerId == idsPlayers[0]);
            var indexChangeHomeIn = this.playersHome.findIndex(player => player.playerId == idsPlayers[1]);
            if (indexChangeHomeOut != -1) {
              this.playersHome[indexChangeHomeOut].idSubstitution = gameEvent.play_id;
              this.playersHome[indexChangeHomeOut].substitutionOut = true;
              this.playersHome[indexChangeHomeOut].substitutionMin = gameEvent.minute;
              this.playersHome[indexChangeHomeOut].substitutionDataID = gameEvent.team_id + '@' + gameEvent.player_id;
            }
            if (indexChangeHomeIn != -1) {
              this.playersHome[indexChangeHomeIn].idSubstitution = gameEvent.play_id;
              this.playersHome[indexChangeHomeIn].substitutionIn = true;
              this.playersHome[indexChangeHomeIn].substitutionMin = gameEvent.minute;
              this.playersHome[indexChangeHomeIn].substitutionDataID = gameEvent.team_id + '@' + gameEvent.player_id;
            }
          }
        } else {
          if (this.playersAway.length > 0) {
            var idsPlayersAway = gameEvent.player_id.split('@');
            var indexChangeAwayOut = this.playersAway.findIndex(player => player.playerId == idsPlayersAway[0]);
            var indexChangeAwayIn = this.playersAway.findIndex(player => player.playerId == idsPlayersAway[1]);
            if (indexChangeHomeOut != -1) {
              this.playersAway[indexChangeAwayOut].idSubstitution = gameEvent.play_id;
              this.playersAway[indexChangeAwayOut].substitutionOut = true;
              this.playersAway[indexChangeAwayOut].substitutionMin = gameEvent.minute;
              this.playersAway[indexChangeAwayOut].substitutionDataID = gameEvent.team_id + '@' + gameEvent.player_id;
            }
            if (indexChangeAwayIn != -1) {
              this.playersAway[indexChangeAwayIn].idSubstitution = gameEvent.play_id;
              this.playersAway[indexChangeAwayIn].substitutionIn = true;
              this.playersAway[indexChangeAwayIn].substitutionMin = gameEvent.minute;
              this.playersAway[indexChangeAwayIn].substitutionDataID = gameEvent.team_id + '@' + gameEvent.player_id;
            }
          }
        }
      }
      if (gameEvent.event_type_id == 1) {
        if (gameEvent.team_id == this.home_team_id) {
          if (this.playersHome.length > 0) {
            var indexPlayerGoalHome = this.playersHome.findIndex(player => player.playerId == gameEvent.player_id);
            if (indexPlayerGoalHome != -1) {
              this.playersHome[indexPlayerGoalHome].goals += 1;
              this.playersHome[indexPlayerGoalHome].goalsMin.push(gameEvent.minute);
              this.playersHome[indexPlayerGoalHome].idsGoals.push(gameEvent.play_id);
            }
          }
        } else {
          if (this.playersAway.length > 0) {
            var indexPlayerGoalAway = this.playersAway.findIndex(player => player.playerId == gameEvent.player_id);
            if (indexPlayerGoalAway != -1) {
              this.playersAway[indexPlayerGoalAway].goals += 1;
              this.playersAway[indexPlayerGoalAway].goalsMin.push(gameEvent.minute);
              this.playersAway[indexPlayerGoalAway].idsGoals.push(gameEvent.play_id);
            }
          }
        }
      }
      if (gameEvent.event_type_id == 2) {
        if (gameEvent.category_id == 8) {
          if (gameEvent.team_id == this.home_team_id) {
            if (this.playersHome.length > 0) {
              var indexPlayerYellowHome = this.playersHome.findIndex(player => player.playerId == gameEvent.player_id);
              if (indexPlayerYellowHome != -1) {
                this.playersHome[indexPlayerYellowHome].yellowcards += 1;
                this.playersHome[indexPlayerYellowHome].yellowcardsMin = gameEvent.minute;
                this.playersHome[indexPlayerYellowHome].idyellowcards = gameEvent.play_id;
              }
            }
          } else {
            if (this.playersAway.length > 0) {
              var indexPlayerYellowAway = this.playersAway.findIndex(player => player.playerId == gameEvent.player_id);
              if (indexPlayerYellowAway != -1) {
                this.playersAway[indexPlayerYellowAway].yellowcards += 1;
                this.playersAway[indexPlayerYellowAway].yellowcardsMin = gameEvent.minute;
                this.playersAway[indexPlayerYellowAway].idyellowcards = gameEvent.play_id;
              }
            }
          }
        }
        if (gameEvent.category_id == 9) {
          if (gameEvent.team_id == this.home_team_id) {
            if (this.playersHome.length > 0) {
              var indexPlayerRedHome = this.playersHome.findIndex(player => player.playerId == gameEvent.player_id);
              if (indexPlayerRedHome != -1) {
                this.playersHome[indexPlayerRedHome].redcards += 1;
                this.playersHome[indexPlayerRedHome].redcardsMin = gameEvent.minute;
                this.playersHome[indexPlayerRedHome].idredcards = gameEvent.play_id;
              }
            }
          } else {
            if (this.playersAway.length > 0) {
              var indexPlayerRedAway = this.playersAway.findIndex(player => player.playerId == gameEvent.player_id);
              if (indexPlayerRedAway != -1) {
                this.playersAway[indexPlayerRedAway].redcards += 1;
                this.playersAway[indexPlayerRedAway].redcardsMin = gameEvent.minute;
                this.playersAway[indexPlayerRedAway].idredcards = gameEvent.play_id;
              }
            }
          }
        }
      }
    },
  },
  methods: {
    getDataSocket() {
      connectChannel(`lineups.game.` + this.$route.params.gameId);
      connectChannel(`mxm.game.` + this.$route.params.gameId);
      connectChannel(`lineups.bench.game.` + this.$route.params.gameId);
    },
    resetPlayUpdate(playType, Team, index, playerIndex) {
      if (playType == 'Substitution') {
        if (Team == 'Home') {
          var idsPlayers = this.playersHome[index].substitutionDataID.split('@');
          var indexChangeHomeOut = this.playersHome.findIndex(player => player.playerId == idsPlayers[1]);
          var indexChangeHomeIn = this.playersHome.findIndex(player => player.playerId == idsPlayers[2]);
          if (indexChangeHomeOut != -1) {
            this.playersHome[indexChangeHomeOut].substitutionOut = false;
            this.playersHome[indexChangeHomeOut].substitutionMin = '';
            this.playersHome[indexChangeHomeOut].substitutionDataID = '';
            this.playersHome[indexChangeHomeOut].idSubstitution = 0;
          }
          if (indexChangeHomeIn != -1) {
            this.playersHome[indexChangeHomeIn].substitutionIn = false;
            this.playersHome[indexChangeHomeIn].substitutionMin = '';
            this.playersHome[indexChangeHomeIn].substitutionDataID = '';
            this.playersHome[indexChangeHomeIn].idSubstitution = 0;
          }
        } else {
          var idsPlayersAway = this.playersAway[index].substitutionDataID.split('@');
          var indexChangeAwayOut = this.playersAway.findIndex(player => player.playerId == idsPlayersAway[1]);
          var indexChangeAwayIn = this.playersAway.findIndex(player => player.playerId == idsPlayersAway[2]);
          if (indexChangeHomeOut != -1) {
            this.playersAway[indexChangeAwayOut].substitutionOut = false;
            this.playersAway[indexChangeAwayOut].substitutionMin = '';
            this.playersAway[indexChangeAwayOut].substitutionDataID = '';
            this.playersAway[indexChangeAwayOut].idSubstitution = 0;
          }
          if (indexChangeAwayIn != -1) {
            this.playersAway[indexChangeAwayIn].substitutionIn = false;
            this.playersAway[indexChangeAwayIn].substitutionMin = '';
            this.playersAway[indexChangeAwayIn].substitutionDataID = '';
            this.playersAway[indexChangeAwayIn].idSubstitution = 0;
          }
        }
      }
      if (playType == 'YellowCard') {
        if (Team == 'Home') {
          this.playersHome[index].yellowcards = 0;
          this.playersHome[index].yellowcardsMin = 0;
          this.playersHome[index].idyellowcards = 0;
        } else {
          this.playersAway[index].yellowcards = 0;
          this.playersAway[index].yellowcardsMin = 0;
          this.playersAway[index].idyellowcards = 0;
        }
      }
      if (playType == 'RedCard') {
        if (Team == 'Home') {
          this.playersHome[index].redcards = 0;
          this.playersHome[index].redcardsMin = 0;
          this.playersHome[index].idredcards = 0;
        } else {
          this.playersAway[index].redcards = 0;
          this.playersAway[index].redcardsMin = 0;
          this.playersAway[index].idredcards = 0;
        }
      }
      if (playType == 'Goals') {
        if (Team == 'Home') {
          this.playersHome[playerIndex].goals = this.playersHome[playerIndex].goals - 1;
          this.playersHome[playerIndex].goalsMin.splice(index, 1);
          this.playersHome[playerIndex].idsGoals.splice(index, 1);
        } else {
          this.playersAway[playerIndex].goals = this.playersAway[playerIndex].goals - 1;
          this.playersAway[playerIndex].goalsMin.splice(index, 1);
          this.playersAway[playerIndex].idsGoals.splice(index, 1);
        }
      }
    },
    setUpResizeObserver() {
      if (window.ResizeObserver) {
        calendarResizeObserver = new ResizeObserver(() => {
          this.gamesContainerWidth = this.$refs.gamesContainer.clientWidth;
          if (this.gamesContainerWidth < 581) {
            this.movil = true;
          } else {
            this.movil = false;
          }
        });
        calendarResizeObserver.observe(this.$refs.gamesContainer);
      } else {
        throw new Error('Resize observer not supported!');
      }
    },
    async getMXM(game) {
      try {
        var dataMXM = await axios
          .get(this.urlMXM + game)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getMXM(game);
            }
            return e;
          });
        for (let index = 0; index < dataMXM.data.plays.length; index++) {
          if (dataMXM.data.plays[index].event_type_id == 3) {
            if (dataMXM.data.plays[index].team_id == this.home_team_id) {
              if (this.playersHome.length > 0) {
                var idsPlayers = dataMXM.data.plays[index].player_id.split('@');
                var indexChangeHomeOut = this.playersHome.findIndex(player => player.playerId == idsPlayers[0]);
                var indexChangeHomeIn = this.playersHome.findIndex(player => player.playerId == idsPlayers[1]);
                if (indexChangeHomeOut != -1) {
                  this.playersHome[indexChangeHomeOut].idSubstitution = dataMXM.data.plays[index].play_id;
                  this.playersHome[indexChangeHomeOut].substitutionOut = true;
                  this.playersHome[indexChangeHomeOut].substitutionMin = dataMXM.data.plays[index].minute;
                  this.playersHome[indexChangeHomeOut].substitutionDataID =
                    dataMXM.data.plays[index].team_id + '@' + dataMXM.data.plays[index].player_id;
                }
                if (indexChangeHomeIn != -1) {
                  this.playersHome[indexChangeHomeIn].idSubstitution = dataMXM.data.plays[index].play_id;
                  this.playersHome[indexChangeHomeIn].substitutionIn = true;
                  this.playersHome[indexChangeHomeIn].substitutionMin = dataMXM.data.plays[index].minute;
                  this.playersHome[indexChangeHomeIn].substitutionDataID =
                    dataMXM.data.plays[index].team_id + '@' + dataMXM.data.plays[index].player_id;
                }
              }
            } else {
              if (this.playersAway.length > 0) {
                var idsPlayersAway = dataMXM.data.plays[index].player_id.split('@');
                var indexChangeAwayOut = this.playersAway.findIndex(player => player.playerId == idsPlayersAway[0]);
                var indexChangeAwayIn = this.playersAway.findIndex(player => player.playerId == idsPlayersAway[1]);
                if (indexChangeAwayOut != -1) {
                  this.playersAway[indexChangeAwayOut].idSubstitution = dataMXM.data.plays[index].play_id;
                  this.playersAway[indexChangeAwayOut].substitutionOut = true;
                  this.playersAway[indexChangeAwayOut].substitutionMin = dataMXM.data.plays[index].minute;
                  this.playersAway[indexChangeAwayOut].substitutionDataID =
                    dataMXM.data.plays[index].team_id + '@' + dataMXM.data.plays[index].player_id;
                }
                if (indexChangeAwayIn != -1) {
                  this.playersAway[indexChangeAwayIn].idSubstitution = dataMXM.data.plays[index].play_id;
                  this.playersAway[indexChangeAwayIn].substitutionIn = true;
                  this.playersAway[indexChangeAwayIn].substitutionMin = dataMXM.data.plays[index].minute;
                  this.playersAway[indexChangeAwayIn].substitutionDataID =
                    dataMXM.data.plays[index].team_id + '@' + dataMXM.data.plays[index].player_id;
                }
              }
            }
          }
          if (dataMXM.data.plays[index].event_type_id == 1) {
            if (dataMXM.data.plays[index].team_id == this.home_team_id) {
              if (this.playersHome.length > 0) {
                var indexPlayerGoalHome = this.playersHome.findIndex(
                  player => player.playerId == dataMXM.data.plays[index].player_id,
                );
                if (indexPlayerGoalHome != -1) {
                  this.playersHome[indexPlayerGoalHome].goals += 1;
                  this.playersHome[indexPlayerGoalHome].goalsMin.push(dataMXM.data.plays[index].minute);
                  this.playersHome[indexPlayerGoalHome].idsGoals.push(dataMXM.data.plays[index].play_id);
                }
              }
            } else {
              if (this.playersAway.length > 0) {
                var indexPlayerGoalAway = this.playersAway.findIndex(
                  player => player.playerId == dataMXM.data.plays[index].player_id,
                );
                if (indexPlayerGoalAway != -1) {
                  this.playersAway[indexPlayerGoalAway].goals += 1;
                  this.playersAway[indexPlayerGoalAway].goalsMin.push(dataMXM.data.plays[index].minute);
                  this.playersAway[indexPlayerGoalAway].idsGoals.push(dataMXM.data.plays[index].play_id);
                }
              }
            }
          }
          if (dataMXM.data.plays[index].event_type_id == 2) {
            if (dataMXM.data.plays[index].category_id == 8) {
              if (dataMXM.data.plays[index].team_id == this.home_team_id) {
                if (this.playersHome.length > 0) {
                  var indexPlayerYellowHome = this.playersHome.findIndex(
                    player => player.playerId == dataMXM.data.plays[index].player_id,
                  );
                  if (indexPlayerYellowHome != -1) {
                    this.playersHome[indexPlayerYellowHome].yellowcards += 1;
                    this.playersHome[indexPlayerYellowHome].yellowcardsMin = dataMXM.data.plays[index].minute;
                    this.playersHome[indexPlayerYellowHome].idyellowcards = dataMXM.data.plays[index].play_id;
                  }
                }
              } else {
                if (this.playersAway.length > 0) {
                  var indexPlayerYellowAway = this.playersAway.findIndex(
                    player => player.playerId == dataMXM.data.plays[index].player_id,
                  );
                  if (indexPlayerYellowAway != -1) {
                    this.playersAway[indexPlayerYellowAway].yellowcards += 1;
                    this.playersAway[indexPlayerYellowAway].yellowcardsMin = dataMXM.data.plays[index].minute;
                    this.playersAway[indexPlayerYellowAway].idyellowcards = dataMXM.data.plays[index].play_id;
                  }
                }
              }
            }
            if (dataMXM.data.plays[index].category_id == 9) {
              if (dataMXM.data.plays[index].team_id == this.home_team_id) {
                if (this.playersHome.length > 0) {
                  var indexPlayerRedHome = this.playersHome.findIndex(
                    player => player.playerId == dataMXM.data.plays[index].player_id,
                  );
                  if (indexPlayerRedHome != -1) {
                    this.playersHome[indexPlayerRedHome].redcards += 1;
                    this.playersHome[indexPlayerRedHome].redcardsMin = dataMXM.data.plays[index].minute;
                    this.playersHome[indexPlayerRedHome].idredcards = dataMXM.data.plays[index].play_id;
                  }
                }
              } else {
                if (this.playersAway.length > 0) {
                  var indexPlayerRedAway = this.playersAway.findIndex(
                    player => player.playerId == dataMXM.data.plays[index].player_id,
                  );
                  if (indexPlayerRedAway != -1) {
                    this.playersAway[indexPlayerRedAway].redcards += 1;
                    this.playersAway[indexPlayerRedAway].redcardsMin = dataMXM.data.plays[index].minute;
                    this.playersAway[indexPlayerRedAway].idredcards = dataMXM.data.plays[index].play_id;
                  }
                }
              }
            }
          }
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getLineUp(game) {
      connectSocket();
      try {
        var data = await axios
          .get(this.url + game)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getLineUp(game);
            }
            return e;
          });
        if (!data || !data.data) {
          throw new Error('There is not lineups for this game.');
        }
        this.dataPlayersHome = data.data.home_team.players;
        this.dataPlayersAway = data.data.away_team.players;
        data.data.home_team.starting_lineup[0].lineup.sort((a, b) =>
          a.position_id > b.position_id ? 1 : b.position_id > a.position_id ? -1 : 0,
        );
        for (let index = 0; index < data.data.home_team.starting_lineup[0].lineup.length; index++) {
          this.home_team_id = data.data.home_team.team_id;
          this.imageExists(
            'https://az755631.vo.msecnd.net/jersey-teams/' + data.data.home_team.team_id + '.svg',
            'home',
            1,
          );
          this.imageExists(
            'https://az755631.vo.msecnd.net/jersey-teams/' + data.data.home_team.team_id + '_g.svg',
            'home',
            0,
          );
          var NameArrayHome = data.data.home_team.starting_lineup[0].lineup[index].player_name.split(',');
          var lastArrayName = NameArrayHome[0].split(' ');
          var ArrayName = NameArrayHome[1].trim().split(' ');
          var lastNameHome = '';
          if (lastArrayName.length == 2 || lastArrayName.length == 1) {
            if (lastArrayName[0].length < 4) {
              lastNameHome = lastArrayName[0] + ' ' + lastArrayName[1];
            } else {
              lastNameHome = lastArrayName[0];
            }
          } else {
            if (lastArrayName.length == 3) {
              lastNameHome = lastArrayName[0] + ' ' + lastArrayName[1];
            } else {
              if (lastArrayName.length == 4) {
                lastNameHome = lastArrayName[1] + ' ' + lastArrayName[2] + ' ' + lastArrayName[3];
              }
            }
          }
          var nameHome =
            ArrayName[ArrayName.length - 1] != ''
              ? ArrayName[ArrayName.length - 1].charAt(0)
              : ArrayName[ArrayName.length - 2]
              ? ArrayName[ArrayName.length - 2].charAt(0)
              : '';
          lastNameHome = lastNameHome.replace(',', '');
          var abrevNamePlayerHome = nameHome + '. ' + lastNameHome;

          var newCooHome = data.data.home_team.starting_lineup[0].lineup[index].x;
          var positionConvertHome = (newCooHome * 50) / 100;
          var indexPlayerDataHome = data.data.home_team.players.findIndex(
            player => player.player_id == data.data.home_team.starting_lineup[0].lineup[index].player_id,
          );
          this.playersHome.push({
            played: true,
            name: data.data.home_team.starting_lineup[0].lineup[index].player_name,
            position: this.positions[data.data.home_team.players[indexPlayerDataHome].position_id - 1].name,
            position_id: data.data.home_team.players[indexPlayerDataHome].position_id - 1,
            imgPlayer:
              'https://az755631.vo.msecnd.net/players-profile/' +
              data.data.home_team.starting_lineup[0].lineup[index].player_id +
              '.png',
            nationality: data.data.home_team.players[indexPlayerDataHome].nationality
              ? data.data.home_team.players[indexPlayerDataHome].nationality.substring(0, 3)
              : '',
            imgNationality: data.data.home_team.players[indexPlayerDataHome].nationality_photo,
            substitutionIn: false,
            substitutionOut: false,
            abrevNamePlayer: abrevNamePlayerHome,
            imgTeam: 'https://az755631.vo.msecnd.net/teams-80/' + data.data.home_team.team_id + '.png',
            substitutionMin: '',
            substitutionDataID: '',
            shirt_number: data.data.home_team.starting_lineup[0].lineup[index].jersey_number,
            positionX: data.data.home_team.starting_lineup[0].lineup[index].y - 13,
            positionY: positionConvertHome * 0.9,
            positionXOrigin: data.data.home_team.starting_lineup[0].lineup[index].y - 17,
            positionYOrigin: data.data.home_team.starting_lineup[0].lineup[index].x - 2,
            playerId: data.data.home_team.starting_lineup[0].lineup[index].player_id,
            goals: 0,
            yellowcards: 0,
            redcards: 0,
            goalsMin: [],
            yellowcardsMin: 0,
            redcardsMin: 0,
            idyellowcards: 0,
            idredcards: 0,
            idsGoals: [],
            idSubstitution: 0,
            isbench: false,
            id_team: data.data.home_team.team_id,
          });
          this.homeTeamLogo = 'https://az755631.vo.msecnd.net/teams-80/' + data.data.home_team.team_id + '.png';
        }
        this.playersHome.sort((a, b) => (a.position_id > b.position_id ? 1 : b.position_id > a.position_id ? -1 : 0));
        if (data.data.home_team.bench) {
          for (let index = 0; index < data.data.home_team.bench.length; index++) {
            this.subsTitle = true;
            var NameArrayHomeBench = data.data.home_team.bench[index].playerName.split(',');
            var lastArrayNameBench = NameArrayHomeBench[0].split(' ');
            var ArrayNameBench = NameArrayHomeBench[1].trim().split(' ');
            var lastNameHomeBench = '';
            if (lastArrayNameBench.length == 2 || lastArrayNameBench.length == 1) {
              if (lastArrayNameBench[0].length < 4) {
                lastNameHomeBench = lastArrayNameBench[0] + ' ' + lastArrayNameBench[1];
              } else {
                lastNameHomeBench = lastArrayNameBench[0];
              }
            } else {
              if (lastArrayNameBench.length == 3) {
                lastNameHomeBench = lastArrayNameBench[0] + ' ' + lastArrayNameBench[1];
              } else {
                if (lastArrayNameBench.length == 4) {
                  lastNameHomeBench = lastArrayNameBench[1] + ' ' + lastArrayNameBench[2] + ' ' + lastArrayNameBench[3];
                }
              }
            }
            var nameHomeBench =
              ArrayNameBench[ArrayNameBench.length - 1] != ''
                ? ArrayNameBench[ArrayNameBench.length - 1].charAt(0)
                : ArrayNameBench[ArrayNameBench.length - 2]
                ? ArrayNameBench[ArrayNameBench.length - 2].charAt(0)
                : '';
            lastNameHomeBench = lastNameHomeBench.replace(',', '');
            var abrevNamePlayerHomeBench = nameHomeBench + '. ' + lastNameHomeBench;
            var indexPlayerDataHomeBench = data.data.home_team.players.findIndex(
              player => player.player_id == data.data.home_team.bench[index].playerID,
            );
            this.playersHome.push({
              played: false,
              name: data.data.home_team.bench[index].playerName,
              position:
                indexPlayerDataHomeBench != -1
                  ? this.positions[data.data.home_team.players[indexPlayerDataHomeBench].position_id - 1].name
                  : '',
              position_id: data.data.home_team.players[indexPlayerDataHomeBench].position_id,
              imgPlayer:
                'https://az755631.vo.msecnd.net/players-profile/' + data.data.home_team.bench[index].playerID + '.png',
              nationality:
                indexPlayerDataHomeBench != -1
                  ? data.data.home_team.players[indexPlayerDataHomeBench].nationality
                    ? data.data.home_team.players[indexPlayerDataHomeBench].nationality.substring(0, 3)
                    : ''
                  : '',
              imgNationality:
                indexPlayerDataHomeBench != -1
                  ? data.data.home_team.players[indexPlayerDataHomeBench].nationality_photo
                  : '',
              substitutionIn: false,
              substitutionOut: false,
              abrevNamePlayer: abrevNamePlayerHomeBench,
              imgTeam: 'https://az755631.vo.msecnd.net/teams-80/' + data.data.home_team.team_id + '.png',
              substitutionMin: '',
              substitutionDataID: '',
              shirt_number: data.data.home_team.bench[index].jerseyNumber,
              positionX: '',
              positionY: '',
              positionXOrigin: '',
              positionYOrigin: '',
              playerId: data.data.home_team.bench[index].playerID,
              goals: 0,
              yellowcards: 0,
              redcards: 0,
              goalsMin: [],
              yellowcardsMin: 0,
              redcardsMin: 0,
              idyellowcards: 0,
              idredcards: 0,
              idsGoals: [],
              idSubstitution: 0,
              isbench: true,
              id_team: data.data.home_team.team_id,
            });
          }
          this.playersHome.sort((a, b) => (a.position_id > b.position_id ? 1 : b.position_id > a.position_id ? -1 : 0));
        }
        for (let index = 0; index < data.data.away_team.starting_lineup[0].lineup.length; index++) {
          this.away_team_id = data.data.away_team.team_id;
          this.imageExists(
            'https://az755631.vo.msecnd.net/jersey-teams/' + data.data.away_team.team_id + '.svg',
            'away',
            1,
          );
          this.imageExists(
            'https://az755631.vo.msecnd.net/jersey-teams/' + data.data.away_team.team_id + '_g.svg',
            'away',
            0,
          );
          var NameArrayAway = data.data.away_team.starting_lineup[0].lineup[index].player_name.split(',');
          var lastArrayNameAway = NameArrayAway[0].split(' ');
          var ArrayNameAway = NameArrayAway[1].trim().split(' ');
          var lastNameAway = '';
          if (lastArrayNameAway.length == 2 || lastArrayNameAway.length == 1) {
            if (lastArrayNameAway[0].length < 4) {
              lastNameAway = lastArrayNameAway[0] + ' ' + lastArrayNameAway[1];
            } else {
              lastNameAway = lastArrayNameAway[0];
            }
          } else {
            if (lastArrayNameAway.length == 3) {
              lastNameAway = lastArrayNameAway[0] + ' ' + lastArrayNameAway[1];
            } else {
              if (lastArrayNameAway.length == 4) {
                lastNameAway = lastArrayNameAway[1] + ' ' + lastArrayNameAway[2] + ' ' + lastArrayNameAway[3];
              }
            }
          }
          var nameAway =
            ArrayNameAway[ArrayName.length - 1] != ''
              ? ArrayNameAway[ArrayNameAway.length - 1].charAt(0)
              : ArrayNameAway[ArrayNameAway.length - 2]
              ? ArrayNameAway[ArrayNameAway.length - 2].charAt(0)
              : '';
          lastNameAway = lastNameAway.replace(',', '');
          var abrevNamePlayerAway = nameAway + '. ' + lastNameAway;

          var newCooAway = data.data.away_team.starting_lineup[0].lineup[index].x;

          // var positionConvert = 100 - ((100 - newCooAway) * 50) / 100;
          var positionConvert = 100 - ((100 - newCooAway) * 50) / 100;
          positionConvert = positionConvert * 0.9;
          var indexPlayerData = data.data.away_team.players.findIndex(
            player => player.player_id == data.data.away_team.starting_lineup[0].lineup[index].player_id,
          );
          this.playersAway.push({
            played: true,
            name: data.data.away_team.starting_lineup[0].lineup[index].player_name,
            position: this.positions[data.data.away_team.players[indexPlayerData].position_id - 1].name,
            position_id: data.data.away_team.players[indexPlayerData].position_id,
            imgPlayer:
              'https://az755631.vo.msecnd.net/players-profile/' +
              data.data.away_team.starting_lineup[0].lineup[index].player_id +
              '.png',
            nationality: data.data.away_team.players[indexPlayerData].nationality
              ? data.data.away_team.players[indexPlayerData].nationality.substring(0, 3)
              : '',
            imgNationality: data.data.away_team.players[indexPlayerData].nationality_photo,
            substitutionIn: false,
            substitutionOut: false,
            abrevNamePlayer: abrevNamePlayerAway,
            imgTeam: 'https://az755631.vo.msecnd.net/teams-80/' + data.data.away_team.team_id + '.png',
            substitutionMin: '',
            substitutionDataID: '',
            shirt_number: data.data.away_team.starting_lineup[0].lineup[index].jersey_number,
            positionX: data.data.away_team.starting_lineup[0].lineup[index].y - 13,
            positionY: positionConvert,
            positionXOrigin: data.data.away_team.starting_lineup[0].lineup[index].y - 17,
            positionYOrigin: data.data.away_team.starting_lineup[0].lineup[index].x - 7,
            playerId: data.data.away_team.starting_lineup[0].lineup[index].player_id,
            goals: 0,
            yellowcards: 0,
            redcards: 0,
            goalsMin: [],
            yellowcardsMin: 0,
            redcardsMin: 0,
            idyellowcards: 0,
            idredcards: 0,
            idsGoals: [],
            idSubstitution: 0,
            isbench: false,
            id_team: data.data.away_team.team_id,
          });
          this.awayTeamLogo = 'https://az755631.vo.msecnd.net/teams-80/' + data.data.away_team.team_id + '.png';
        }
        this.playersAway.sort((a, b) => (a.position_id > b.position_id ? 1 : b.position_id > a.position_id ? -1 : 0));
        if (data.data.away_team.bench) {
          for (let index = 0; index < data.data.away_team.bench.length; index++) {
            this.subsTitle = true;
            var NameArrayAwayBench = data.data.away_team.bench[index].playerName.split(',');
            var lastArrayNameBenchAway = NameArrayAwayBench[0].split(' ');
            var ArrayNameBenchAway = NameArrayAwayBench[1].trim().split(' ');
            var lastNameAwayBench = '';
            if (lastArrayNameBenchAway.length == 2 || lastArrayNameBenchAway.length == 1) {
              if (lastArrayNameBenchAway[0].length < 4) {
                lastNameAwayBench = lastArrayNameBenchAway[0] + ' ' + lastArrayNameBenchAway[1];
              } else {
                lastNameAwayBench = lastArrayNameBenchAway[0];
              }
            } else {
              if (lastArrayNameBenchAway.length == 3) {
                lastNameAwayBench = lastArrayNameBenchAway[0] + ' ' + lastArrayNameBenchAway[1];
              } else {
                if (lastArrayNameBenchAway.length == 4) {
                  lastNameAwayBench =
                    lastArrayNameBenchAway[1] + ' ' + lastArrayNameBenchAway[2] + ' ' + lastArrayNameBenchAway[3];
                }
              }
            }
            var TnameBenchAway =
              ArrayNameBenchAway[ArrayNameBenchAway.length - 1] != ''
                ? ArrayNameBenchAway[ArrayNameBenchAway.length - 1].charAt(0)
                : ArrayNameBenchAway[ArrayNameBenchAway.length - 2]
                ? ArrayNameBenchAway[ArrayNameBenchAway.length - 2].charAt(0)
                : '';
            lastNameAwayBench = lastNameAwayBench.replace(',', '');
            var abrevNamePlayerAwayBench = TnameBenchAway + '. ' + lastNameAwayBench;
            var indexPlayerDataAwayBench = data.data.away_team.players.findIndex(
              player => player.player_id == data.data.away_team.bench[index].playerID,
            );
            this.playersAway.push({
              played: false,
              name: data.data.away_team.bench[index].playerName,
              position:
                indexPlayerDataAwayBench != -1
                  ? this.positions[data.data.away_team.players[indexPlayerDataAwayBench].position_id - 1].name
                  : '',
              position_id: data.data.away_team.players[indexPlayerDataAwayBench].position_id,
              imgPlayer:
                'https://az755631.vo.msecnd.net/players-profile/' + data.data.away_team.bench[index].playerID + '.png',
              nationality:
                indexPlayerDataAwayBench != -1
                  ? data.data.away_team.players[indexPlayerDataAwayBench].nationality
                    ? data.data.away_team.players[indexPlayerDataAwayBench].nationality.substring(0, 3)
                    : ''
                  : '',
              imgNationality:
                indexPlayerDataAwayBench != -1
                  ? data.data.away_team.players[indexPlayerDataAwayBench].nationality_photo
                  : '',
              substitutionIn: false,
              substitutionOut: false,
              abrevNamePlayer: abrevNamePlayerAwayBench,
              imgTeam: 'https://az755631.vo.msecnd.net/teams-80/' + data.data.away_team.team_id + '.png',
              substitutionMin: '',
              substitutionDataID: '',
              shirt_number: data.data.away_team.bench[index].jerseyNumber,
              positionX: '',
              positionY: '',
              positionXOrigin: '',
              positionYOrigin: '',
              playerId: data.data.away_team.bench[index].playerID,
              goals: 0,
              yellowcards: 0,
              redcards: 0,
              goalsMin: [],
              yellowcardsMin: 0,
              redcardsMin: 0,
              idyellowcards: 0,
              idredcards: 0,
              idsGoals: [],
              idSubstitution: 0,
              isbench: true,
              id_team: data.data.away_team.team_id,
            });
          }
          this.playersAway.sort((a, b) => (a.position_id > b.position_id ? 1 : b.position_id > a.position_id ? -1 : 0));
        }
        this.getMXM(this.$route.params.gameId);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    closeChange() {
      this.subsActive = false;
    },
    imageExists(image_url, team, type) {
      var http = new XMLHttpRequest();
      var requestFlag = 0;
      if (team == 'away') {
        if (type == 1) {
          if (this.uniformAway == '') {
            http.open('HEAD', image_url, false);
            http.send();
            requestFlag = 1;
          }
        } else {
          if (this.uniformAwayG == '') {
            http.open('HEAD', image_url, false);
            http.send();
            requestFlag = 1;
          }
        }
      } else {
        if (type == 1) {
          if (this.uniformHome == '') {
            http.open('HEAD', image_url, false);
            http.send();
            requestFlag = 1;
          }
        } else {
          if (this.uniformHomeG == '') {
            http.open('HEAD', image_url, false);
            http.send();
            requestFlag = 1;
          }
        }
      }
      if (requestFlag == 1) {
        if (http.status == 200) {
          if (team == 'away') {
            if (type == 1) {
              this.uniformAway = 'url(' + image_url + ')';
            } else {
              this.uniformAwayG = 'url(' + image_url + ')';
            }
          } else {
            if (type == 1) {
              this.uniformHome = 'url(' + image_url + ')';
            } else {
              this.uniformHomeG = 'url(' + image_url + ')';
            }
          }
        } else {
          if (team == 'away') {
            if (type == 1) {
              this.uniformAway = 'url(https://az755631.vo.msecnd.net/jersey-teams/default_visita.svg)';
            } else {
              this.uniformAwayG = 'url(https://az755631.vo.msecnd.net/jersey-teams/default_visita_g.svg)';
            }
          } else {
            if (type == 1) {
              this.uniformHome = 'url(https://az755631.vo.msecnd.net/jersey-teams/default_local.svg)';
            } else {
              this.uniformHomeG = 'url(https://az755631.vo.msecnd.net/jersey-teams/default_local_g.svg)';
            }
          }
        }
      }
    },
    viewChange(ids) {
      var idSubstitution = ids.split('@');
      if (idSubstitution[0] == this.home_team_id) {
        var indexChangeHomeOut = this.playersHome.findIndex(player => player.playerId == idSubstitution[1]);
        var indexChangeHomeIn = this.playersHome.findIndex(player => player.playerId == idSubstitution[2]);
        if (indexChangeHomeOut != -1) {
          this.substitutionModal.min = this.playersHome[indexChangeHomeOut].substitutionMin;
          this.substitutionModal.in.name = this.playersHome[indexChangeHomeOut].abrevNamePlayer;
          this.substitutionModal.in.teamImg = this.playersHome[indexChangeHomeOut].imgTeam;
          this.substitutionModal.in.shirt_number = this.playersHome[indexChangeHomeOut].shirt_number;
          this.substitutionModal.in.playerImg = this.playersHome[indexChangeHomeOut].imgPlayer;
          this.substitutionModal.in.nationalityimg = this.playersHome[indexChangeHomeOut].imgNationality;
        } else {
          this.substitutionModal.in.name = '';
          this.substitutionModal.in.teamImg = '';
          this.substitutionModal.in.shirt_number = '';
          this.substitutionModal.in.playerImg = '';
          this.substitutionModal.in.nationalityimg = '';
        }
        if (indexChangeHomeIn != -1) {
          this.substitutionModal.out.name = this.playersHome[indexChangeHomeIn].abrevNamePlayer;
          this.substitutionModal.out.teamImg = this.playersHome[indexChangeHomeIn].imgTeam;
          this.substitutionModal.out.playerImg = this.playersHome[indexChangeHomeIn].imgPlayer;
          this.substitutionModal.out.nationalityimg = this.playersHome[indexChangeHomeIn].imgNationality;
          this.substitutionModal.out.shirt_number = this.playersHome[indexChangeHomeIn].shirt_number;
        } else {
          this.substitutionModal.out.name = '';
          this.substitutionModal.out.teamImg = '';
          this.substitutionModal.out.shirt_number = '';
          this.substitutionModal.out.playerImg = '';
          this.substitutionModal.out.nationalityimg = '';
        }
      } else {
        var indexChangeAwayOut = this.playersAway.findIndex(player => player.playerId == idSubstitution[1]);
        var indexChangeAwayIn = this.playersAway.findIndex(player => player.playerId == idSubstitution[2]);
        if (indexChangeAwayOut != -1) {
          this.substitutionModal.min = this.playersAway[indexChangeAwayOut].substitutionMin;
          this.substitutionModal.in.name = this.playersAway[indexChangeAwayOut].abrevNamePlayer;
          this.substitutionModal.in.teamImg = this.playersAway[indexChangeAwayOut].imgTeam;
          this.substitutionModal.in.playerImg = this.playersAway[indexChangeAwayOut].imgPlayer;
          this.substitutionModal.in.nationalityimg = this.playersAway[indexChangeAwayOut].imgNationality;
          this.substitutionModal.in.shirt_number = this.playersAway[indexChangeAwayOut].shirt_number;
        } else {
          this.substitutionModal.in.name = '';
          this.substitutionModal.in.teamImg = '';
          this.substitutionModal.in.shirt_number = '';
          this.substitutionModal.in.playerImg = '';
          this.substitutionModal.in.nationalityimg = '';
        }
        if (indexChangeAwayIn != -1) {
          this.substitutionModal.out.name = this.playersAway[indexChangeAwayIn].abrevNamePlayer;
          this.substitutionModal.out.teamImg = this.playersAway[indexChangeAwayIn].imgTeam;
          this.substitutionModal.out.playerImg = this.playersAway[indexChangeAwayIn].imgPlayer;
          this.substitutionModal.out.nationalityimg = this.playersAway[indexChangeAwayIn].imgNationality;
          this.substitutionModal.out.shirt_number = this.playersAway[indexChangeAwayIn].shirt_number;
        } else {
          this.substitutionModal.out.name = '';
          this.substitutionModal.out.teamImg = '';
          this.substitutionModal.out.shirt_number = '';
          this.substitutionModal.out.playerImg = '';
          this.substitutionModal.out.nationalityimg = '';
        }
      }
      this.subsActive = true;
    },
    viewLineupTeam(opt) {
      this.teamActive = opt;
    },
  },
};
</script>

<style lang="scss">
.container-no-lineups {
  width: 100%;
  border-radius: 5px;
  height: 360px;
  border: 2px dashed #dadada;
  display: inline-block;
  &__img-search {
    width: 100%;
    margin-top: 90px;
  }
  &__title-info {
    width: 100%;
    margin-top: 14px;
    text-align: center;
    font-family: 'Circular-Std';
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.52px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
    @media screen and (max-width: 350px) {
      font-size: 16px;
    }
  }
  &__subtitle-info {
    width: 100%;
    text-align: center;
    margin-top: 4px;
    font-family: 'Avenir-Roman';
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.12px;
    color: #9a9a9a;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
    @media screen and (max-width: 350px) {
      font-size: 10px;
    }
    @media screen and (max-width: 300px) {
      font-size: 9px;
    }
  }
}
.notification-no-lineups.movil {
  font-size: 13px;
  padding: 15px 10px 15px 26px;
  @media screen and (max-width: 830px) {
    top: 25%;
    padding: 15px 26px 15px 26px;
  }
  @media screen and (max-width: 768px) {
    top: 30%;
    padding: 15px 0px 15px 0px;
    font-size: 9px;
  }
  @media screen and (max-width: 530px) {
    padding: 15px 26px 15px 26px;
    font-size: 11px;
  }
  @media screen and (max-width: 500px) {
    font-size: 10px;
    top: 25%;
  }
  @media screen and (max-width: 460px) {
    padding: 15px 14px 15px 14px;
    font-size: 10px;
  }
  @media screen and (max-width: 420px) {
    padding: 15px 8px 15px 8px;
  }
  @media screen and (max-width: 400px) {
    top: 18%;
    font-size: 9px;
    padding: 15px 6px 15px 6px;
  }
  @media screen and (max-width: 370px) {
    width: 78%;
    left: 11%;
  }
  @media screen and (max-width: 300px) {
    font-size: 8px;
    width: 90%;
    padding: 15px 3px 15px 3px;
    left: 5%;
  }
}
.notification-no-lineups {
  width: 74%;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid white;
  padding: 15px 100px 15px 100px;
  border-radius: 12px;
  border: solid 2.4px #ffffff;
  background-color: rgba(29, 29, 29, 0.6);
  font-family: 'Avenir-Pro-Medium';
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  left: 13%;
  top: 35%;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  @media screen and (max-width: 900px) {
    padding: 15px 60px 15px 60px;
  }
}
.no-margin-rigth {
  margin-right: 1px !important;
}
.text-bar-results {
  width: 16px;
  height: 18px;
  line-height: 18px;
  float: left;
  margin-left: 5px;
  margin-right: 5px;
  font-family: 'Avenir-Medium';
  font-size: 11px;
  font-weight: 500;
  text-align: right;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #5a5a5a;
}
.margin-flag-top-subs {
  margin-top: 12px !important;
  @media screen and (max-width: 580px) {
    margin-top: 7px !important;
  }
  @media screen and (max-width: 400px) {
    margin-top: 8px !important;
  }
}
.name-list {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cardsDisplay {
  display: inline-block;
}
.cardsNotDisplay {
  display: none;
}
.yellowred_card_div {
  width: 18px;
  height: 18px;
  position: absolute;
  right: -12px;
  top: 6px;
  background: url('/assets/icons/yellow_and_red.png') no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  @media screen and (max-width: 650px) {
    width: 14px;
    height: 14px;
    right: -7px;
  }
  @media screen and (max-width: 400px) {
    top: -3px;
  }
}
.cont-results-players {
  width: 100%;
  height: 20px;
  margin-left: 5px;
  display: -webkit-inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-box-pack: center;
  -webkit-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-flex-align: center;
  -webkit-align-items: center;
}
.red-icon-item {
  width: 18px;
  height: 18px;
  float: left;
  background: url('/assets/icons/red_card.png') no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  @media screen and (max-width: 650px) {
    width: 14px;
    height: 14px;
  }
}
.yellow-icon-item {
  width: 18px;
  height: 18px;
  float: left;
  background: url('/assets/icons/yellow_card.png') no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  @media screen and (max-width: 650px) {
    width: 14px;
    height: 14px;
  }
}
.redyellow-icon-item {
  width: 18px;
  height: 18px;
  float: left;
  background: url('/assets/icons/yellow_and_red.png') no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  @media screen and (max-width: 650px) {
    width: 14px;
    height: 14px;
  }
}
.gol-icon-item {
  width: 18px;
  height: 18px;
  float: left;
  background: url('/assets/icons/players-goals-con-goles.png') no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  @media screen and (max-width: 650px) {
    width: 14px;
    height: 14px;
  }
}
.goal_card_div {
  width: 18px;
  height: 18px;
  position: absolute;
  left: -12px;
  top: 6px;
  background: url('/assets/icons/players-goals-con-goles.png') no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  @media screen and (max-width: 650px) {
    width: 14px;
    height: 14px;
    left: -7px;
  }
}
.red_card_div {
  width: 22px;
  height: 24px;
  position: absolute;
  right: -11px;
  top: 6px;
  background: url('/assets/icons/red_card.png') no-repeat center center;
  background-size: 74%;
  @media screen and (max-width: 650px) {
    width: 14px;
    height: 14px;
    right: -7px;
  }
  @media screen and (max-width: 400px) {
    top: -3px;
    background-size: 70%;
  }
}
.yellow_card_div {
  width: 22px;
  height: 24px;
  position: absolute;
  right: -11px;
  top: 6px;
  background: url('/assets/icons/yellow_card.png') no-repeat center center;
  background-size: 67% 16px;
  @media screen and (max-width: 650px) {
    width: 14px;
    height: 14px;
    right: -7px;
  }
  @media screen and (max-width: 400px) {
    top: -3px;
    background-size: 60%;
  }
}
.size-browser-class {
  visibility: hidden;
  position: absolute;
}
.num__abs {
  position: absolute;
  right: 15px;
}
.border {
  border-right: solid 1px #acacac;
}
.substitution-player-data-container {
  display: -webkit-inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-box-pack: center;
  -webkit-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-flex-align: center;
  -webkit-align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
  height: 240px;
  &__img-player {
    width: 72px;
    margin-left: 34px;
    height: 98px;
    position: relative;
    margin-top: 8px;
    & > img {
      border-radius: 8px;
    }
    &__team {
      width: 28px;
      height: 28px;
      position: absolute;
      top: -4px;
      left: -15px;
    }
    &__img-cont {
      width: 20px;
      height: 20px;
      bottom: -10px;
      right: -10px;
      border-radius: 20px;
      background-position: center center;
      background-size: cover;
      overflow: hidden;
      position: absolute;
    }
  }
  &__name-player {
    width: 100%;
    height: 25px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    text-align: center;
    margin-top: 15px;
    color: #545454;
    font-family: 'Avenir-Pro-Bold';
  }
  &__position-player {
    width: 100%;
    height: 16px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    margin-top: -4px;
    letter-spacing: normal;
    text-align: center;
    font-family: 'Avenir-Medium';
    color: #545454;
  }
  &__shirt-player {
    width: 100%;
    text-align: center;
    height: 40px;
    font-family: 'Avenir-Pro-Bold';
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: -1.05px;
    text-align: center;
    color: #545454;
    & > span {
      font-size: 18px;
    }
  }
  &__substitution-player {
    width: 100%;
    text-align: center;
    height: 25px;
  }
}
.modal-substitutions {
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 24, 0.49);
  position: absolute;
  z-index: 2;
  display: -webkit-inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-box-pack: center;
  -webkit-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-flex-align: center;
  -webkit-align-items: center;

  &__substitution-container {
    width: 280px;
    height: 272px;
    background: #ffffff;
    border-radius: 8px;
    &__header {
      width: 100%;
      height: 32px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #2c2b2b;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content auto;
      span {
        color: white;
        height: 32px;
        line-height: 32px;
        font-family: 'Avenir-Medium';
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
    &__players-container {
      width: 100%;
      height: 240px;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: min-content auto;
    }
  }
}
.logo-Field.movil {
  width: 34px;
}
.logo-Field {
  position: absolute;
  width: 63px;
  height: auto;
  @media screen and (max-width: 700px) {
    width: 58px;
  }
  @media screen and (max-width: 610px) {
    width: 48px;
  }
}
.item-player-field.movil {
  width: 50px;
  height: 42px;
}
.item-player-field {
  width: 64px;
  height: 55px;
  position: absolute;
  @media screen and (max-width: 650px) {
    width: 50px;
    height: 42px;
  }
  &__jersey.movil {
    background-position: center 2px;
    height: 33px;
    position: relative;
    @media screen and (max-width: 400px) {
      width: 25px;
      height: 27px;
    }
  }
  &__jersey {
    width: 38px;
    height: 37px;
    display: inline-flex;
    background-repeat: no-repeat;
    background-position: center 3px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: relative;
    background-size: cover;
    @media screen and (max-width: 650px) {
      background-position: center 2px;
      height: 33px;
    }
    @media screen and (max-width: 400px) {
      width: 25px;
      height: 27px;
    }
    &__num.movil {
      margin-left: 1px;
      position: relative;
      @media screen and (max-width: 400px) {
        font-size: 9px;
        width: 25px;
        height: 27px;
        margin-top: 3px;
        margin-left: 0px;
        line-height: 29px;
      }
    }
    &__num {
      text-align: center;
      line-height: 30px;
      width: 34px;
      height: 30px;
      margin-top: 10px;
      position: relative;
      margin-left: 2px;
      font-family: 'Avenir-Demi';
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      @media screen and (max-width: 400px) {
        font-size: 9px;
        width: 25px;
        height: 27px;
        margin-top: 0px;
        margin-left: 0px;
        line-height: 29px;
      }
    }
  }
  &__info.movil {
    height: 16px;
    margin-top: -3px;
    @media screen and (max-width: 400px) {
      background-color: transparent;
    }
  }
  &__info {
    width: 100%;
    height: 18px;
    opacity: 0.8;
    line-height: 18px;
    position: relative;
    border-radius: 4.4px;
    background-color: #141f08;
    @media screen and (max-width: 650px) {
      height: 16px;
      margin-top: -3px;
    }
    @media screen and (max-width: 400px) {
      background-color: transparent;
    }
    &__name.movil {
      font-size: 8px;
      @media screen and (max-width: 400px) {
        display: none;
      }
    }
    &__name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: 'Avenir-Demi';
      font-size: 9px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      @media screen and (max-width: 650px) {
        font-size: 8px;
      }
      @media screen and (max-width: 400px) {
        display: none;
      }
    }
    &__change.movil {
      width: 16px;
      height: 16px;
      @media screen and (max-width: 400px) {
        top: -12px;
        right: 1px;
      }
    }
    &__change {
      width: 18px;
      height: 18px;
      background-color: #5075ff;
      display: -webkit-inline-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -webkit-box-pack: center;
      border-radius: 4px;
      -webkit-flex-pack: center;
      -webkit-justify-content: center;
      -webkit-flex-align: center;
      -webkit-align-items: center;
      vertical-align: top;
      position: absolute;
      top: 0px;
      @media screen and (max-width: 650px) {
        width: 16px;
        height: 16px;
      }
      right: -15px;
      & > :hover {
        cursor: pointer;
      }
      @media screen and (max-width: 400px) {
        top: -12px;
        right: 1px;
      }
    }
  }
}
.item-player-field.active {
  display: inline-block;
}
.item-player-field.disabled {
  display: none;
}

.nomargin {
  margin-right: 38px !important;
  margin-top: -3px !important;
}
.substitution-indicator.movil {
  width: 85px;
  height: 28px;
  line-height: 28px;
  border-radius: 5px;
  border: solid 2px #dfdfdf;
  margin-top: 0px;
  font-size: 18px;
  @media screen and (max-width: 400px) {
    width: 51px;
    height: 20px;
    border-radius: 3.6px;
    margin-top: 3px;
    float: right;
    margin-right: 15%;
    line-height: 18px;
    font-size: 14px;
  }
}
.substitution-indicator {
  width: 61px;
  height: 20px;
  border: solid 1.4px #dfdfdf;
  border-radius: 3.6px;
  margin-top: 1px;
  float: right;
  margin-right: 20%;
  display: grid;
  grid-template-columns: 56% 44%;
  grid-template-rows: min-content auto;
  font-size: 12.9px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #545454;
  text-align: center;
  @media screen and (max-width: 620px) {
    margin-right: 12%;
  }
  @media screen and (max-width: 400px) {
    width: 61px;
    height: 20px;
    border-radius: 3.6px;
    margin-top: 3px;
    float: right;
    margin-right: 15%;
    line-height: 18px;
    font-size: 14px;
  }
}

.lineup-container-home {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
  &__item-lineup.movil {
    height: 112px;
  }
  &__item-lineup {
    width: 100%;
    height: 88px;
    border-bottom: 2px solid #ebebeb;
    display: inline-flex;
    &__image-cont.movil {
      height: 88px;
      width: 104px;

      @media screen and (max-width: 380px) {
        height: 88px;
        width: 85px;
      }
    }
    &__image-cont {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content auto;
      height: 88px;
      width: 95px;
      &__item.movil {
        margin-left: 23px !important;
        width: 64px !important;
        height: 87px !important;
        margin-right: 24px !important;
        margin-top: 14px;
        & > img {
          width: 64px !important;
        }
        @media screen and (max-width: 620px) {
          margin-left: 6px;
        }

        @media screen and (max-width: 380px) {
          margin-left: 13px !important;
          margin-right: 14px !important;
        }
      }
      &__item {
        width: 53px;
        height: 72px;
        margin-left: 32px;
        border-radius: 4px;
        margin-right: 10px;
        margin-top: 8px;
        position: relative;
        & > img {
          border-radius: 8px;
        }
        &__team.movil {
          width: 25px;
          height: 25px;
          top: -10px;
          left: -10px;
        }
        &__team {
          width: 20px;
          height: 20px;
          position: absolute;
          top: -7px;
          left: -7px;
        }
        @media screen and (max-width: 620px) {
          margin-left: 6px;
        }
        @media screen and (max-width: 380px) {
          margin-left: 13px !important;
          margin-right: 14px !important;
        }
      }
      @media screen and (max-width: 380px) {
        height: 88px;
        width: 85px;
      }
    }
    &__data-cont.movil {
      margin-top: 14px;
    }
    &__data-cont {
      width: 100%;
      height: 88px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content auto;
      &__name.movil {
        font-size: 26px;
        line-height: 42px;
        @media screen and (max-width: 440px) {
          font-size: 22px;
        }
      }
      &__name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        height: 35px;
        font-family: 'Avenir-Pro-Bold';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        text-transform: uppercase;
        font-style: normal;
        line-height: 50px;
        letter-spacing: normal;
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-rows: min-content auto;
        color: #545454;
        @media screen and (max-width: 440px) {
          font-size: 22px;
        }
      }
      &__position.movil {
        font-size: 18px;

        @media screen and (max-width: 415px) {
          font-size: 14px;
        }
      }
      &__position {
        font-family: 'Avenir-Medium';
        height: 20px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 27px;
        letter-spacing: normal;
        color: #545454;
        @media screen and (max-width: 415px) {
          font-size: 14px;
        }
      }
      &__info-cont.movil {
        grid-template-columns: 7% 15% 52% 26%;

        @media screen and (max-width: 400px) {
          grid-template-columns: 9% 15% 45% 31%;
        }
      }
      &__info-cont {
        display: grid;
        grid-template-columns: 7% 15% 57% 21%;
        grid-template-rows: min-content auto;
        height: 24px;
        @media screen and (max-width: 820px) {
          grid-template-columns: 7% 15% 52% 26%;
        }
        @media screen and (max-width: 735px) {
          grid-template-columns: 7% 15% 47% 31%;
        }
        @media screen and (max-width: 650px) {
          grid-template-columns: 9% 15% 40% 36%;
        }
        @media screen and (max-width: 400px) {
          grid-template-columns: 9% 15% 45% 31%;
        }
        &__img {
          height: 24px;
          &__cont.movil {
            width: 20px;
            height: 20px;
            margin-top: 2px;
          }
          &__cont {
            width: 16px;
            height: 16px;
            margin-top: 4px;
            border-radius: 16px;
            background-position: center center;
            background-size: cover;
            overflow: hidden;
            position: relative;
          }
        }
        &__abrev.movil {
          font-size: 18px;
          margin-top: 2px;
        }
        &__abrev {
          font-family: 'Avenir-Medium';
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #545454;
          margin-top: 5px;
          text-transform: uppercase;
          margin-left: 2px;
        }
        &__num.movil {
          font-size: 34px;
          text-align: right;
          position: relative;
          padding-right: 8px;
          @media screen and (max-width: 380px) {
            font-size: 23px;
            margin-top: 5px;
            letter-spacing: -2px;
          }
          & > span {
            font-size: 24px;
          }
        }
        &__num {
          font-family: 'Avenir-Pro-Bold';
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          position: relative;
          height: 24px;
          line-height: 19px;
          letter-spacing: -1.05px;
          text-align: right;
          padding-right: 15px;
          color: #545454;
          @media screen and (max-width: 620px) {
            font-size: 26px;
          }
          @media screen and (max-width: 380px) {
            font-size: 27px;
          }
          & > span {
            font-family: 'Avenir-Medium';
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.67px;
            text-align: left;
            color: #545454;
          }
        }
      }
    }
  }
}
.button-team-view-lineup.movil.active {
  border-color: #86b6ff !important;
}
.button-team-view-lineup.movil.disabled {
  opacity: 0.6;
}
.logo-home-team.movil {
  width: 72px;
  margin-top: 4px;
  @media screen and (max-width: 390px) {
    width: 60px;
    margin-top: 10px;
  }
}
.logo-home-team {
  float: left;
  margin-top: 8px;
  @media screen and (max-width: 390px) {
    width: 60px;
    margin-top: 10px;
  }
}
.logo-home-away.movil {
  width: 72px;
  margin-top: 4px;
  @media screen and (max-width: 390px) {
    width: 60px;
    margin-top: 10px;
  }
}
.logo-home-away {
  float: right;
  margin-top: 8px;
  @media screen and (max-width: 390px) {
    width: 60px;
    margin-top: 10px;
  }
}
.sep-title-teams-menu.movil {
  display: none;
}
.sep-title-teams-menu {
  width: 1px;
  height: 32px;
  margin-top: 24px;
  border-left: 1px dotted #878787;
}
.lineup-players {
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
  margin-top: -12px;
  &__container {
    width: 100%;
    height: auto;
  }
  &__field-container.movil {
    height: 295px;
  }
  &__field-container {
    width: 100%;
    height: 426px;
    background: #fcfcfc;
    display: grid;
    position: relative;
    @media screen and (max-width: 700px) {
      height: 396px;
    }
    @media screen and (max-width: 670px) {
      height: 380px;
    }
    @media screen and (max-width: 620px) {
      height: 346px;
    }
    @media screen and (max-width: 570px) {
      height: 316px;
    }
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    &__container-cancha.movil {
      height: 188px;
      @media screen and (max-width: 480px) {
        height: 170px;
      }
      @media screen and (max-width: 445px) {
        height: 155px;
      }
      @media screen and (max-width: 420px) {
        height: 145px;
      }
      @media screen and (max-width: 400px) {
        height: 130px;
      }
      @media screen and (max-width: 350px) {
        height: 120px;
      }
      @media screen and (max-width: 325px) {
        height: 110px;
      }
      @media screen and (max-width: 300px) {
        height: 100px;
      }
    }
    &__container-cancha {
      width: 92%;
      position: relative;
      height: 284px;

      background: url('/assets/icons/cancha_horizontal.png') no-repeat center center;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
      margin-left: 4%;

      @media screen and (max-width: 700px) {
        height: 264px;
      }
      @media screen and (max-width: 670px) {
        height: 244px;
      }
      @media screen and (max-width: 620px) {
        height: 224px;
      }
      @media screen and (max-width: 570px) {
        height: 204px;
      }
      @media screen and (max-width: 480px) {
        height: 170px;
      }
      @media screen and (max-width: 445px) {
        height: 155px;
      }
      @media screen and (max-width: 420px) {
        height: 145px;
      }
      @media screen and (max-width: 380px) {
        height: 130px;
      }
      @media screen and (max-width: 350px) {
        height: 120px;
      }
      @media screen and (max-width: 325px) {
        height: 110px;
      }
      @media screen and (max-width: 300px) {
        height: 100px;
      }
    }
    &__indicador-team.movil {
      height: 77px;
      grid-template-columns: 15% 70% 15%;
    }
    &__indicador-team {
      width: 100%;
      height: 107px;
      display: grid;
      grid-template-columns: 15% 35% 35% 15%;
      grid-template-rows: min-content auto;
      @media screen and (max-width: 700px) {
        height: 97px;
      }
      @media screen and (max-width: 610px) {
        height: 77px;
      }
      &__home-arrow.movil {
        height: 57px;
        & > img {
          width: 138px;
          margin-top: -3px;
        }
      }
      &__home-arrow {
        width: 100%;
        height: 107px;
        @media screen and (max-width: 700px) {
          height: 97px;
        }
        @media screen and (max-width: 610px) {
          height: 77px;
        }
        position: relative;
        text-align: center;
        display: -webkit-inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -webkit-box-pack: center;
        -webkit-flex-pack: center;
        -webkit-justify-content: center;
        -webkit-flex-align: center;
        -webkit-align-items: center;
        vertical-align: top;
        & > img {
          width: 205px;
        }
      }
      &__home-arrow.active {
        display: -webkit-inline-flex;
      }

      &__home-arrow.disabled {
        display: none;
      }
      &__away-arrow.active {
        display: -webkit-inline-flex;
      }
      &__away-arrow.disabled {
        display: none;
      }
      &__away-arrow.movil {
        height: 57px;
        & > img {
          width: 138px;
          margin-top: -3px;
        }
      }
      &__away-arrow {
        width: 100%;
        position: relative;
        height: 107px;
        @media screen and (max-width: 700px) {
          height: 97px;
        }
        @media screen and (max-width: 610px) {
          height: 77px;
        }
        text-align: center;
        display: -webkit-inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -webkit-box-pack: center;
        -webkit-flex-pack: center;
        -webkit-justify-content: center;
        -webkit-flex-align: center;
        -webkit-align-items: center;
        vertical-align: top;
        & > img {
          width: 205px;
        }
      }
    }
  }
  &__pleca-lineup-container {
    width: 100%;
    background: #fafafa;
    height: 72px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    span {
      color: #4b4b4b;
      height: 72px;
      line-height: 72px;
      font-family: 'Circular-Std-Medium';
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
  &__header-container {
    width: 100%;
    background: #2c2b2b;
    height: 48px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    span {
      color: white;
      height: 40px;
      line-height: 48px;
      font-family: 'Circular-Std-Medium';
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }
  &__teams-menu-container.movil {
    height: 112px;
    & > div {
      display: grid;
      width: 5%;
    }
    & > div:first-child {
      width: 40%;
      grid-template-columns: 1fr 50%;
      grid-template-rows: min-content auto;
      text-align: right;
      height: 88px;
      margin-top: 12px;
      border-radius: 8px;
      border: solid 4px #ffffff;
      @media screen and (max-width: 360px) {
        grid-template-columns: 1fr 55%;
      }
    }
    & > div:last-child {
      width: 40%;
      display: grid;
      grid-template-columns: 50% 1fr;
      grid-template-rows: min-content auto;
      text-align: left;
      height: 88px;
      margin-top: 12px;
      border-radius: 8px;
      border: solid 4px #ffffff;
      @media screen and (max-width: 360px) {
        grid-template-columns: 55% 1fr;
      }
    }
  }
  &__teams-menu-container {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    text-align: left;
    border-bottom: 2px solid #ebebeb;
    &__name_team.movil {
      height: 88px;
      line-height: 88px;
      font-size: 24px;
      @media screen and (max-width: 390px) {
        font-size: 18px;
        line-height: 80px;
        letter-spacing: -0.7px;
      }
    }
    &__name_team {
      font-family: 'Circular-Std-Medium';
      font-size: 21.3px;
      height: 80px;
      font-weight: 500;
      line-height: 80px;
      text-align: center;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #4b4b4b;
      @media screen and (max-width: 390px) {
        font-size: 18px;
        line-height: 80px;
        letter-spacing: -0.7px;
      }
    }
    & > div {
      width: 0.4%;
      display: grid;
    }
    & > div:first-child {
      width: 49.8%;
      grid-template-columns: 1fr 40%;
      grid-template-rows: min-content auto;
      text-align: right;
      @media screen and (max-width: 360px) {
        grid-template-columns: 1fr 55%;
      }
    }

    & > div:last-child {
      width: 49.8%;
      display: grid;
      grid-template-columns: 40% 1fr;
      grid-template-rows: min-content auto;
      text-align: left;
      @media screen and (max-width: 360px) {
        grid-template-columns: 55% 1fr;
      }
    }
  }
  &__lineup-container.movil {
    & > div {
      width: 1%;
    }
    & > div:first-child {
      width: 100%;
    }

    & > div:last-child {
      width: 100%;
    }
  }
  &__lineup-container {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 15px;
    justify-content: center;
    text-align: left;
    & > div {
      width: 4%;
      @media screen and (max-width: 620px) {
        width: 1%;
      }
    }
    & > div:first-child {
      width: 48%;
      @media screen and (max-width: 620px) {
        width: 49%;
      }
    }

    & > div:last-child {
      width: 48%;
      @media screen and (max-width: 620px) {
        width: 49%;
      }
    }
  }

  .name_team_short {
    display: none;
  }
  .name_team_short.movil {
    display: inline-block;
  }
  .name_team_full {
    display: inline-block;
    text-transform: uppercase;
    line-height: 20px;
    padding-top: 20px;
  }
  .name_team_full.movil {
    display: none;
    text-transform: uppercase;
    line-height: 20px;
    padding-top: 20px;
  }
  .disabledTeam.movil {
    display: none;
  }
  .visibleTeam.movil {
    display: inline-block;
  }
  .activeSub {
    display: -webkit-inline-flex !important;
  }
  .deactiveSub {
    display: none !important;
  }
  .close-susbtitutions {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-family: 'Circular-Std-Medium';
    float: right;
    display: -webkit-inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -webkit-box-pack: center;
    -webkit-flex-pack: center;
    -webkit-justify-content: center;
    -webkit-flex-align: center;
    -webkit-align-items: center;
    & > :hover {
      cursor: pointer;
    }
  }
}
</style>
